import { useTrackLink, useTrackView } from '@seek/cmsu-analytics';
import { useTranslations } from '@vocab/react';
import { Alert, Text, TextLink } from 'braid-design-system';
import { useEffect, useState } from 'react';

import {
  CONTENT_ELEMENT_PRESSED,
  SELECT_ACCOUNT_PENDING_ALERT_VIEW,
} from 'src/constants/pageViewUniqueKeys';
import type { UserTrackingInfo } from 'src/modules/AnalyticsProvider/useAuthenticatedViewTracking';

import translations from './.vocab';

interface AccountPendingBannerProps {
  pendingAccountsLength: number;
  userInfo: UserTrackingInfo | undefined;
}

const AccountPendingBanner = ({
  pendingAccountsLength,
  userInfo,
}: AccountPendingBannerProps) => {
  const [pendingAlertDismissed, setPendingAlertDismissed] = useState(
    window.sessionStorage.getItem('pendingAlertDismissed') !== null,
  );
  const { t } = useTranslations(translations);
  const showPendingAlert = pendingAccountsLength > 0 && !pendingAlertDismissed;

  const trackClickViewPending = useTrackLink(CONTENT_ELEMENT_PRESSED, {
    elementDesign: 'link',
    elementText: 'view pending accounts',
    elementAction: 'direct to pending activation section ',
    actionOrigin: 'pending accounts banner',
    ...userInfo,
  });

  const alertMessage = t('You have pending accounts', {
    count: pendingAccountsLength,
    Link: (children) => (
      <TextLink
        href="#pending-accounts"
        onClick={() =>
          trackClickViewPending ? trackClickViewPending() : undefined
        }
      >
        {children}
      </TextLink>
    ),
  });

  const trackClosePendingBanner = useTrackLink(CONTENT_ELEMENT_PRESSED, {
    elementDesign: 'button',
    elementText: 'X',
    elementAction: 'close alert',
    actionOrigin: 'pending accounts banner',
    ...userInfo,
  });

  const trackPendingBannerView = useTrackView(
    SELECT_ACCOUNT_PENDING_ALERT_VIEW,
    {
      ...userInfo,
      alertShownType: 'banner',
      alertShownTone: 'cautionary',
      alertShownPosition: 'header',
      alertShownTitle: 'pending accounts to activate',
      alertShownMessageCode: alertMessage?.toString() || '',
    },
  );

  useEffect(() => {
    if (showPendingAlert && userInfo?.userId && trackPendingBannerView) {
      trackPendingBannerView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.userId]);

  const dismissPendingAlert = () => {
    window.sessionStorage.setItem('pendingAlertDismissed', 'true');
    setPendingAlertDismissed(true);

    if (trackClosePendingBanner) {
      trackClosePendingBanner();
    }
  };

  return (
    showPendingAlert && (
      <Alert
        tone="caution"
        onClose={dismissPendingAlert}
        closeLabel="pending-banner-close"
      >
        <Text>{alertMessage}</Text>
      </Alert>
    )
  );
};

export default AccountPendingBanner;
