import { Box } from 'braid-design-system';

import * as styles from './ResponsiveImage.css';

interface Props {
  image: JSX.Element;
}

export const ResponsiveImage = ({ image }: Props) => (
  <Box className={styles.root}>
    <Box className={styles.image}>{image}</Box>
  </Box>
);
