import type { AnalyticsEvent } from '@seek/adv-header-footer';

import getPageTrackingData from './utils/getPageTrackingData';
import isTrackingEnabled from './utils/isTrackingEnabled';
import setPageTrackingData from './utils/setPageTrackingData';
import trackLink from './utils/trackLink';
import trackView from './utils/trackView';

// eg: trackPageLoad('home-page-loaded, TRACKING_PAGE_DATA);
export function trackPageLoad(eventName: string, pageData: any) {
  if (isTrackingEnabled()) {
    trackView(eventName, pageData);
    setPageTrackingData(pageData);
  }
}

// eg: trackPageInteractions('register-button-clicked', 'registration-form');
export function trackPageInteraction(
  eventName: string,
  actionOrigin: string,
  additionalTrackingData = {},
) {
  if (isTrackingEnabled()) {
    const currentPageTrackingData = getPageTrackingData();
    trackLink(eventName, {
      actionOrigin,
      ...currentPageTrackingData,
      ...additionalTrackingData,
    });
  }
}

export const trackHeaderFooterInteractions =
  (currentPage?: string) => (analyticsEvent: AnalyticsEvent) => {
    if (isTrackingEnabled()) {
      const currentPageTrackingData = getPageTrackingData();
      trackLink(analyticsEvent.eventName, {
        ...(currentPage ? { currentPage } : {}),
        ...currentPageTrackingData,
        ...analyticsEvent,
      });
    }
  };
