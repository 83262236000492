import gql from 'graphql-tag';

import type {
  HeaderFooterContext,
  HeaderFooterContextInput,
} from 'src/graphql/generated';
import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';

export const query = gql`
  query GetHeaderFooterContext($input: HeaderFooterContextInput!) {
    headerFooterContext {
      identityContext {
        email
        act(input: $input) {
          operatorEmail
          isCsView
        }
      }
      advertiser {
        billingId
        hasMultipleAccounts
        isActivationPending
        isAgency
        name
        permissionOptions {
          canCreateJobs
          canManageBrand
          canManageUsers
          canPayInvoices
          canViewInvoiceHistory
          canViewManagerReports
          canManageCompanyProfile
          canViewInsightsRoleReport
        }
        showOnCreditHoldMessage
        switchAccountsHref
      }
      user {
        name
        firstName
        id
      }
    }
  }
`;

export type GetHeaderFooterContext = (
  input?: HeaderFooterContextInput,
) => Promise<HeaderFooterContext>;

const getHeaderFooterContext: GetHeaderFooterContext = async (input = {}) => {
  const graphqlClient = getClient();
  const result = await graphqlClient.query<{
    headerFooterContext: HeaderFooterContext;
  }>({
    variables: { input },
    query,
  });

  return result.data.headerFooterContext;
};

export default getHeaderFooterContext;
