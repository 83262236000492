export const REGISTER_COMPLETE_PAGE_VIEW = 'register-complete-page-view';
export const USER_CONFIRM_PAGE_VIEW = 'user-confirm-page-view';
export const HOME_PAGE_VIEW = 'home_page_view';

// Advertiser Select Screen
export const SELECT_ACCOUNT_PAGE_VIEW = 'select-account-page-view';
export const SELECT_ACCOUNT_PENDING_ALERT_VIEW = 'alert_viewed';
export const CONTENT_ELEMENT_PRESSED = 'content_element_pressed';
export const RESEND_ACTIVATION_EMAIL_PRESSED =
  'resend_activation_email_pressed';

// Additional Adverter Confirm
export const ADDITIONAL_ADVERTISER_PAGE_VIEW =
  'account_registration_additional_confirmation_displayed';
export const ADDITIONAL_ADVERTISER_PAGE_CLICK_CAJA_LINK =
  'create_job_ad_pressed';

// Register Form
export const REGISTER_ACCOUNT_PAGE_VIEW = 'account_registration_displayed';
export const REGISTER_ACCOUNT_CLICK_AWAY = 'click_away_from_field_pressed';
export const REGISTER_ACCOUNT_SUBMIT = 'registration_button_pressed';
export const REGISTER_ACCOUNT_SUBMIT_SUCCEED =
  'registration_form_submit_succeeded';
export const REGISTER_ACCOUNT_FIRST_FOCUS_FIELDS =
  'form_first_interaction_pressed';
export const REGISTER_ACCOUNT_FORM_VALIDATION_ERROR =
  'form_validation_error_displayed';
export const REGISTER_ACCOUNT_SWITCH_ACCOUNT = 'switch_accounts_pressed';
export const REGISTER_ACCOUNT_ERROR = 'alert_viewed';
export const REGISTER_ACCOUNT_NAV_CLICKED = 'navigation_pressed';
export const TOOLTIP_PRESSED = 'tooltip_pressed';
