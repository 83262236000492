import type { Feature } from 'src/modules/FeatureToggler/types';
import { getFeatureByName } from 'src/modules/FeatureToggler/utils/getFeatureByName';
import type { Environment } from 'src/types';
import { isProd } from 'src/utils/environment';

export const getFeatureEnabled = (
  features: Feature[],
  name: string,
): boolean | undefined => getFeatureByName(features, name)?.enabled;

export const getIsOverriden = (overrides: Feature[], name: string): boolean =>
  typeof getFeatureEnabled(overrides, name) === 'boolean';

export const getIsEnabled = (
  overrides: Feature[],
  features: Feature[],
  name: string,
): boolean =>
  getFeatureEnabled(overrides, name) ??
  getFeatureEnabled(features, name) ??
  false;

export const upsertFeature = (
  features: Feature[],
  feature: Feature,
): Feature[] => {
  const result = [...features];
  const existingOverrideIndex = result?.findIndex(
    (existing) => existing.name === feature.name,
  );
  if (existingOverrideIndex >= 0) {
    result[existingOverrideIndex] = feature;
  } else {
    result.push(feature);
  }
  return result;
};

export const isOverridesAllowed = (environment: Environment) =>
  !isProd(environment);
