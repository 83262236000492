import { oauth } from '@seek/oauth-url-builders';
import { useEffect } from 'react';

import { useConfig } from 'src/hooks/context';

const LoginRedirect = () => {
  const { locale } = useConfig();

  useEffect(() => {
    window.location.assign(
      oauth.buildLoginUrl({
        language: locale,
      }),
    );
  }, [locale]);

  return null;
};

export default LoginRedirect;
