import {
  ENV_DEV,
  ENV_PRODUCTION,
  ENV_STAGING,
} from 'src/constants/environments';
import type { Environment } from 'src/types';

export const isLocalDev = (environment: Environment): boolean =>
  environment === ENV_DEV;

export const isStaging = (environment: Environment): boolean =>
  environment === ENV_STAGING;

export const isProd = (environment: Environment): boolean =>
  environment === ENV_PRODUCTION;

export const isServerRendering = (): boolean => typeof window === 'undefined';
