import { useTranslations } from '@vocab/react';
import { TextField, Dropdown, Checkbox } from 'braid-design-system';
import type { CountryCode } from 'libphonenumber-js';
import type { FocusEvent } from 'react';

import { useRegistrationPageContext } from 'src/views/pages/RegistrationPage/RegistrationPageContext';
import translations from 'src/views/shared/forms/RegistrationForm/.vocab';
import useFields from 'src/views/shared/forms/RegistrationForm/BusinessDetailsCard/useBusinessDetailsFields';
// import {
//   BusinessIdentifier,
//   isBusinessNumberCountry,
// } from 'src/views/shared/forms/RegistrationForm/BusinessIdentifier/BusinessIdentifier';
import { FormSection } from 'src/views/shared/forms/RegistrationForm/FormSection/FormSection';
import { PhoneNumber } from 'src/views/shared/forms/RegistrationForm/PhoneNumber/PhoneNumber';
import { useRegistrationForm } from 'src/views/shared/forms/RegistrationForm/RegistrationFormContext';
import { filterUnsupportedCountries } from 'src/views/shared/forms/utils/filterUnsupportedCountries.ts';
// import {
//   TaxIdentificationNumber,
//   isTaxIdentityCountry,
// } from 'src/views/shared/forms/RegistrationForm/TaxIdentificationNumber/TaxIdentificationNumber.tsx';

interface Props {
  handleFieldBlur: (event: FocusEvent<HTMLInputElement>) => void;
  handleFieldFocus: (event: FocusEvent<HTMLInputElement>) => void;
  email: string;
}

const BusinessDetailsCard = ({
  handleFieldFocus,
  handleFieldBlur,
  email,
}: Props) => {
  const { values, setFieldValue } = useRegistrationForm();
  const context = useRegistrationPageContext();
  const { businessName, isAgency, phoneNumber, phoneNumberCountryCode } =
    useFields();
  const { t } = useTranslations(translations);

  if (context.loading) {
    return null;
  }

  const { registrationOptions, countries } = context;

  const getCountryCodeFromCountryName = (): CountryCode =>
    (countries.options.find((country) => country.name === values.country)
      ?.countryCode || 'AU') as CountryCode;

  const getCountryNameFromCountryCode = (countryCode: string): string =>
    countries.options.find((country) => country.countryCode === countryCode)
      ?.name || '';

  const setCountryNameFromCountryCode = (
    e: React.FormEvent<HTMLSelectElement>,
  ) => {
    setFieldValue(
      'country',
      getCountryNameFromCountryCode(e.currentTarget.value),
    );
    setFieldValue('countryCode', e.currentTarget.value);
  };

  const countryCode = getCountryCodeFromCountryName();

  const filteredCountries = filterUnsupportedCountries(countries.options);

  return (
    <FormSection heading={t('Business Details')}>
      <TextField
        id="businessName"
        label={t('Business name')}
        description={t('Business name description')}
        data={{ 'tracking-id': 'business-name' }}
        value={businessName.value}
        tone={businessName.errorMessage ? 'critical' : undefined}
        message={businessName.errorMessage}
        onChange={businessName.onChange}
        onFocus={handleFieldFocus}
        onBlur={(e) => {
          handleFieldBlur(e);
          businessName.onBlur();
        }}
        reserveMessageSpace
      />

      <Dropdown
        label={t('Country')}
        id="country"
        value={countryCode}
        placeholder={t('Country select message')}
        onChange={(e) => setCountryNameFromCountryCode(e)}
        reserveMessageSpace
      >
        {filteredCountries.map((country) => (
          <option key={country.countryCode} value={country.countryCode}>
            {country.name}
          </option>
        ))}
      </Dropdown>

      <PhoneNumber
        id="phoneNumber"
        label={t('Phone number')}
        description={t('Phone number description')}
        onPhoneNumberChange={(p) => phoneNumber.onChange(p || '')}
        onPhoneNumberCountryCodeChange={(p) =>
          phoneNumberCountryCode.onChange(p || '')
        }
        onPhoneNumberBlur={phoneNumber.onBlur}
        message={phoneNumber.errorMessage}
        businessCountryCode={countryCode}
      />
      {/* {selectedCountryCode && isTaxIdentityCountry(selectedCountryCode) && (
        <TaxIdentificationNumber
          country={selectedCountryCode}
          onBlur={handleFieldBlur}
        />
      )}

      {selectedCountryCode && isBusinessNumberCountry(selectedCountryCode) && (
        <BusinessIdentifier
          country={selectedCountryCode}
          onBlur={handleFieldBlur}
        />
      )} */}

      {registrationOptions.allowAgencyRegistration && (
        <Checkbox
          label={t('Register as Agency')}
          checked={isAgency.value}
          id="isAgency"
          onChange={isAgency.onChange}
          description={t('Register as Agency description', {
            email,
          })}
        />
      )}
    </FormSection>
  );
};

export default BusinessDetailsCard;
