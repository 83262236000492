import {
  required,
  rules,
  badWords,
  notHttpUrl,
  notNumberOnly,
  disallowInsecureCharacters,
} from '@seek/validators-js';
import { useTranslations } from '@vocab/react';

import translations from 'src/views/shared/forms/RegistrationForm/.vocab';
import { useField } from 'src/views/shared/forms/RegistrationForm/RegistrationFormContext';
import { noStartingSpace } from 'src/views/shared/forms/utils/customValidationHelpers';

export default () => {
  const { t } = useTranslations(translations);

  const firstNameValidationRules = rules.hirerFirstName({
    requiredLabel: t('First name required'),
  });

  const lastNameValidationRules = rules.hirerLastName({
    requiredLabel: t('Last name required'),
  });

  const firstName = useField({
    id: 'firstName',
    validators: [
      required,
      badWords,
      notHttpUrl,
      notNumberOnly,
      noStartingSpace,
      disallowInsecureCharacters,
      ...firstNameValidationRules.validators,
    ],
    validatorProps: {
      ...firstNameValidationRules.validatorProps,
      fieldLabel: t('Given name'),
    },
  });

  const lastName = useField({
    id: 'lastName',
    validators: [
      required,
      badWords,
      notHttpUrl,
      notNumberOnly,
      noStartingSpace,
      disallowInsecureCharacters,
      ...lastNameValidationRules.validators,
    ],
    validatorProps: {
      ...lastNameValidationRules.validatorProps,
      fieldLabel: t('Family name'),
    },
  });

  return {
    firstName,
    lastName,
  };
};
