import gql from 'graphql-tag';

import type { Identity } from 'src/graphql/generated';
import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';

export const query = gql`
  query GetIdentity {
    identity {
      subject {
        email
      }
    }
  }
`;

const getIdentity = async () => {
  const graphqlClient = getClient();
  const result = await graphqlClient.query<{ identity: Identity }>({
    query,
  });

  const { identity } = result.data;
  return identity;
};

export default getIdentity;
