export const METRIC_PREFIX = 'hirer_acquisition';

export const METRIC_LABELS = {
  PAGE_ENTRY: 'page-entry',
  PAGE_LOAD: 'page-loaded',
  INTERACTION: 'interaction',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

export const METRIC_INFO = {
  REGISTRATION_REQUEST_SENT: 'registration-request-sent',
  ADCENTRE_REGISTRATION_SUCCESS: 'passed-adcentre-registration',
  SSO_SIGNUP_SUCCESS: 'passed-sso-signup',
  REGISTER_EXISTING_SUCCESS: 'register-existing-success',
  REGISTER_EXISTING_SSO_ONLY_SUCCESS: 'register-sso-only-success',
  REGISTER_NEW_SUCCESS: 'register-new-success',
  ADCENTRE_EMAIL_CHECK_SUCCESS: 'passed-adcentre-email-check',
  SSO_EMAIL_CHECK_SUCCESS: 'passed-sso-email-check',
};

export const METRIC_WARNINGS = {
  SSO_USER_EXISTS: 'sso-user-exists',
  ADCENTRE_USER_EXISTS: 'adcentre-user-exists',
  RE_CHECK_EMAIL: 're-check-email',
  FAILED_EMAIL_RE_CHECK: 'failed-re-check-email',
};

export const METRIC_ERRORS = {
  SSO_SIGNUP: 'failed-sso-signup',
  ADCENTRE_REGISTRATION: 'failed-adcentre-registration',
  SSO_EMAIL_CHECK: 'failed-sso-email-check',
  ADCENTRE_EMAIL_CHECK: 'failed-adcentre-email-check',
};
