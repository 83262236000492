export const IconSuccessTick = (props: any) => (
  <svg
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M69.713 45.558a6.568 6.568 0 1 1-13.137 0 6.568 6.568 0 0 1 13.137 0Zm-6.569-2.218a2.217 2.217 0 1 0 0 4.435 2.217 2.217 0 0 0 0-4.434Z"
      fill="#FDC221"
    />
    <path
      d="M38.67 35.662a6.612 6.612 0 1 1-13.224 0 6.612 6.612 0 0 1 13.223 0Zm-6.612-2.232a2.232 2.232 0 1 0 0 4.463 2.232 2.232 0 0 0 0-4.463Z"
      fill="#56D3DF"
    />
    <path
      d="M50.008 48.842c-5.442 0-9.853 4.41-9.853 9.852h9.853v-9.852Z"
      fill="#6E56E6"
    />
    <path
      d="M92.702 25.852c5.441 0 9.852 4.412 9.852 9.853h-9.852v-9.852Z"
      fill="#FDC221"
    />
    <path
      d="m80.527 25.853-6.967 6.966 2.322 2.323 6.967-6.967-2.322-2.322Z"
      fill="#56D3DF"
    />
    <path
      d="M94.343 42.313H91.06a4.916 4.916 0 0 1-3.3 4.633V45.59a4.924 4.924 0 0 0-4.918-4.92 4.924 4.924 0 0 0-4.918 4.92 4.924 4.924 0 0 0 4.918 4.918h1.343a4.915 4.915 0 0 1-4.619 3.26v3.284c4.002 0 7.337-2.885 8.05-6.683 3.82-.694 6.728-4.039 6.728-8.056Zm-13.136 3.276c0-.9.733-1.634 1.634-1.634.902 0 1.635.733 1.635 1.635v1.634H82.84a1.636 1.636 0 0 1-1.634-1.634Z"
      fill="#6E56E6"
    />
    <path
      d="M54.934 22.561a4.924 4.924 0 0 0-4.918-4.918 4.924 4.924 0 0 0-4.919 4.918v1.343a4.915 4.915 0 0 1-3.26-4.62h-3.285c0 4.002 2.886 7.338 6.684 8.05.694 3.82 4.038 6.729 8.056 6.729v-3.285a4.915 4.915 0 0 1-4.633-3.299h1.357a4.924 4.924 0 0 0 4.918-4.918Zm-6.553 0c0-.901.733-1.634 1.635-1.634.9 0 1.634.733 1.634 1.634 0 .901-.733 1.634-1.634 1.634H48.38v-1.634Z"
      fill="#EE399E"
    />
    <path
      d="M69.713 65.263a3.284 3.284 0 1 0 0-6.569 3.284 3.284 0 0 0 0 6.569Z"
      fill="#F470BD"
    />
    <path
      d="M69.713 22.568a3.284 3.284 0 1 0 0-6.568 3.284 3.284 0 0 0 0 6.568Z"
      fill="#FDC221"
    />
    <path
      d="m58.898 65.826-2.322 2.322 6.967 6.967 2.322-2.322-6.967-6.967Z"
      fill="#56D3DF"
    />
    <path
      d="M62.035 112a9.85 9.85 0 0 1-6.967-2.886L38.461 92.505c-3.848-3.847-3.848-10.085 0-13.933 3.847-3.847 10.087-3.846 13.933 0l9.64 9.642 25.147-25.145c3.849-3.847 10.089-3.848 13.933 0 3.848 3.848 3.848 10.086 0 13.934l-32.113 32.112A9.853 9.853 0 0 1 62.035 112Z"
      fill="#EE399E"
    />
  </svg>
);
