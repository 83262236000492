import {
  createAbsoluteUrl,
  createUrlResolver,
  siteNames,
} from '@seek/melways-sites';
import { type ReactNode, createContext, useContext } from 'react';

import type createConfig from 'src/config';
import { getCountryFromSite } from 'src/utils/getCountryFromSite';

export type ConfigType = ReturnType<typeof createConfig>;
export interface ConfigUtils {
  isAnz: boolean;
  isStaging: boolean;
  urlResolver: ReturnType<typeof createUrlResolver>;
  absoluteEmployerUrlResolver: (
    path: string,
  ) => ReturnType<typeof createAbsoluteUrl>;
}

const ConfigContext = createContext<(ConfigType & ConfigUtils) | null>(null);

export const ConfigProvider = ({
  config,
  children,
}: {
  config: ConfigType;
  children: ReactNode;
}) => {
  const { site, locale } = config;
  const isStaging = config.environment === 'staging';
  const isAnz =
    site === siteNames.employer.seek.au || site === siteNames.employer.seek.nz;
  const urlResolver = createUrlResolver({
    site,
    language: locale,
    staging: isStaging,
  });

  const absoluteEmployerUrlResolver = (path: string) =>
    createAbsoluteUrl({
      product: 'employer',
      language: locale,
      country: getCountryFromSite(site),
      path,
    });

  const configUtils: ConfigUtils = {
    isAnz,
    isStaging,
    urlResolver,
    absoluteEmployerUrlResolver,
  };

  return (
    <ConfigContext.Provider value={{ ...config, ...configUtils }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const configContext = useContext(ConfigContext);

  if (configContext === null) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return configContext;
};
