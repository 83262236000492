import { Stack, Heading, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

interface Props {
  heading: string;
  description?: string;
  children: ComponentProps<typeof Stack>['children'];
}

export const FormSection = ({ heading, description, children }: Props) => (
  <Stack space="large">
    <Stack space="small">
      <Heading component="h3" level="4">
        {heading}
      </Heading>
      {description ? <Text>{description}</Text> : null}
    </Stack>
    <Stack space="small">{children}</Stack>
  </Stack>
);
