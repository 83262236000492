import { isServerRendering } from './environment';

export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

interface SessionParams {
  v?: string;
  ftc?: string;
}

export const setSessionParams = (params: SessionParams) => {
  if (params.v) {
    sessionStorage.setItem(QUERY_PARAM_VERSION, params.v);
  }
};

export const QUERY_PARAM_VERSION = 'v';

export const persistQueryParams = () => {
  if (isServerRendering()) {
    return;
  }

  // check URL for UI version query param
  const { v } = getQueryParams();
  // if query params exist, save to session storage
  if (v) {
    setSessionParams({ v });
  } else {
    // otherwise, check sessionStorage
    const sessionVersion = sessionStorage.getItem(QUERY_PARAM_VERSION);

    // if params exist in sessionStorage, add to URL as query string and reload the page
    if (sessionVersion) {
      const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      const urlParams = [];

      if (sessionVersion) {
        urlParams.push(`v=${encodeURIComponent(sessionVersion)}`);
      }

      const url = `${baseUrl}?${urlParams.join('&')}`;

      window.location.replace(url);
    }
  }
};
