import { Helmet } from 'react-helmet';

export interface Props {
  url: string;
  title: string;
  description: string;
  additionalMeta?: any[];
}

const SeoMeta = ({ title, description, url, additionalMeta = [] }: Props) => (
  <Helmet
    htmlAttributes={{
      lang: 'en',
      itemtype: `http://schema.org/Webpage`,
    }}
    title={title}
    link={[{ rel: 'canonical', href: url }]}
    meta={[
      { itemprop: 'name', content: title },
      { itemprop: 'description', content: description },
      { name: 'description', content: description },
      ...additionalMeta,
    ]}
  />
);

export default SeoMeta;
