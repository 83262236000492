import { useTranslations } from '@vocab/react';
import { IconSend, Text, TextLinkButton } from 'braid-design-system';

import type { accountPartsFragment } from 'src/graphql/generated';
import type { PendingAccountEmailStatus } from 'src/views/pages/SelectAccountPage/SelectAccountPage';

import translations from './.vocab';

interface AccountActionButtonProps {
  accountNode: accountPartsFragment;
  handleActivateAccount: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    node: accountPartsFragment,
  ) => void;
  pendingAccountEmailStatus: PendingAccountEmailStatus | undefined;
}

const AccountActionButton = ({
  accountNode,
  handleActivateAccount,
  pendingAccountEmailStatus,
}: AccountActionButtonProps) => {
  const { t } = useTranslations(translations);

  const deriveAction = () => {
    if (!pendingAccountEmailStatus) {
      return (
        <TextLinkButton
          icon={<IconSend alignY="lowercase" />}
          onClick={(e) => handleActivateAccount(e, accountNode)}
        >
          {t('Resend activation email')}
        </TextLinkButton>
      );
    }

    return pendingAccountEmailStatus.success
      ? t('Email sent')
      : t('Try again later');
  };

  return <Text tone="secondary">{deriveAction()}</Text>;
};

export default AccountActionButton;
