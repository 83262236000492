import {
  type AuthStrategy,
  createHirerAuthStrategy,
  type TokenData,
} from '@seek/online-identity';

let strategy: AuthStrategy;

export default (): Promise<TokenData> => {
  if (!strategy) {
    strategy = createHirerAuthStrategy();
  }

  return strategy.get();
};
