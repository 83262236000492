import {
  URL_REGISTER_ADVERTISER_COMPLETE,
  URL_CONTACTUS,
  URL_ACCOUNT_SELECT,
} from '@seek/adv-constants';
import { useTrackLink } from '@seek/cmsu-analytics';
import { JOB_SEARCH_SITE } from '@seek/shared-constants';
import { useTranslations } from '@vocab/react';
import {
  Actions,
  Button,
  ButtonLink,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import { type FocusEvent, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { URL_REGISTER_AGENCY_COMPLETE } from 'src/constants/page-urls';
import {
  REGISTER_ACCOUNT_PAGE_VIEW,
  REGISTER_ACCOUNT_CLICK_AWAY,
  REGISTER_ACCOUNT_SUBMIT,
  REGISTER_ACCOUNT_FIRST_FOCUS_FIELDS,
  REGISTER_ACCOUNT_SUBMIT_SUCCEED,
  REGISTER_ACCOUNT_NAV_CLICKED,
} from 'src/constants/pageViewUniqueKeys';
import { useConfig } from 'src/hooks/context';
import useAuthenticatedTracking from 'src/modules/AnalyticsProvider/useAuthenticatedViewTracking';
import type { AccountSelectionResults } from 'src/types';
import { useRegistrationPageContext } from 'src/views/pages/RegistrationPage/RegistrationPageContext';
import ErrorMessage from 'src/views/shared/ErrorMessage/ErrorMessage';
import FormLoader from 'src/views/shared/FormLoader/FormLoader';

import translations from './.vocab';
import BusinessDetailsCard from './BusinessDetailsCard/BusinessDetailsCard';
import PersonalDetailsCard from './PersonalDetailsCard/PersonalDetailsCard';
import {
  type FormValues,
  RegistrationFormProvider,
  useRegistrationForm,
} from './RegistrationFormContext';

const RegistrationForm = () => {
  const { handleSubmit } = useRegistrationForm();

  const [userInfo] = useAuthenticatedTracking({
    eventName: REGISTER_ACCOUNT_PAGE_VIEW,
  });
  const clickAwayTracking = useTrackLink(REGISTER_ACCOUNT_CLICK_AWAY, {
    ...userInfo,
  });
  const registerSubmitTracking = useTrackLink(REGISTER_ACCOUNT_SUBMIT, {
    ...userInfo,
    actionOrigin: 'account registration form',
  });
  const registerFirstInteraction = useTrackLink(
    REGISTER_ACCOUNT_FIRST_FOCUS_FIELDS,
    {
      ...userInfo,
    },
  );
  const trackRegisterSuccess = useTrackLink(REGISTER_ACCOUNT_SUBMIT_SUCCEED, {
    ...userInfo,
  });
  const trackNavClicked = useTrackLink(REGISTER_ACCOUNT_NAV_CLICKED, {
    ...userInfo,
    actionOrigin: 'account registration form',
  });

  const [state, setState] = useState({
    csRequired: false,
    isProcessingForm: false,
    fieldInteracted: false,
    serverError: false,
    redirectToPage: false,
    registeredAsAgency: false,
  });

  const {
    csRequired,
    fieldInteracted,
    isProcessingForm,
    redirectToPage,
    serverError,
    registeredAsAgency,
  } = state;
  const context = useRegistrationPageContext();
  const { t } = useTranslations(translations);

  const { site, urlResolver, isAnz, absoluteEmployerUrlResolver } = useConfig();

  if (context.loading) {
    return <FormLoader />;
  }

  const { submit, userExists, accountSelectionOptions, identity } = context;

  const registrationFlow = userExists ? 'additional account' : 'new account';

  const submitRegistration = (formValues: FormValues) => {
    if (serverError) {
      return;
    }

    const isCountryAndPhoneAreaCodeAligned = (
      formValues.countryCode.toLowerCase() ===
      formValues.phoneNumberCountryCode.toLowerCase()
    ).toString();

    if (registerSubmitTracking)
      registerSubmitTracking({
        registrationFlow,
        isCountryAndPhoneAreaCodeAligned,
      });
    setState({ ...state, isProcessingForm: true });
    submit(formValues)
      .then((redirectUrl: string) => {
        if (trackRegisterSuccess)
          trackRegisterSuccess({
            registrationFlow,
            isCountryAndPhoneAreaCodeAligned,
          });
        if (redirectUrl) {
          window.location.assign(redirectUrl);
          return;
        }

        setState({
          ...state,
          redirectToPage: true,
          registeredAsAgency: formValues.isAgency,
        });
      })
      .catch(() => {
        setState({
          ...state,
          serverError: true,
        });
      });
  };

  const isAccountSwitchingAvailable =
    (accountSelectionOptions as AccountSelectionResults).totalCount > 1;

  const handleFieldFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (fieldInteracted) {
      return;
    }

    const { trackingId = '' } = event.currentTarget.dataset;
    if (registerFirstInteraction)
      registerFirstInteraction({
        fieldName: trackingId,
      });
    setState({ ...state, fieldInteracted: true });
  };

  const handleFieldBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { trackingId = '' } = event.currentTarget.dataset;
    if (clickAwayTracking)
      clickAwayTracking({
        fieldName: trackingId,
      });
  };

  if (redirectToPage || registeredAsAgency) {
    const redirectUrl = registeredAsAgency
      ? URL_REGISTER_AGENCY_COMPLETE
      : URL_REGISTER_ADVERTISER_COMPLETE;

    return (
      <Navigate
        to={urlResolver({ path: redirectUrl })}
        state={{
          registeredUserDetails: {
            email: identity.subject.email,
          },
        }}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(submitRegistration)}>
      <Stack space="xlarge">
        <Stack space="medium">
          {!userExists && (
            <PersonalDetailsCard
              handleFieldBlur={handleFieldBlur}
              handleFieldFocus={handleFieldFocus}
            />
          )}
          <BusinessDetailsCard
            handleFieldBlur={handleFieldBlur}
            handleFieldFocus={handleFieldFocus}
            email={identity.subject.email}
          />
        </Stack>
        {serverError && (
          <ErrorMessage
            isAnz={isAnz}
            contactCustomerServiceUrl={absoluteEmployerUrlResolver(
              URL_CONTACTUS,
            )}
            description={t('Registration form error')}
            nextStep={t('Registration form error next step')}
            site={site}
            showCsContactStep={true}
            trackingEventName="REGISTER_ACCOUNT_PAGE_VIEW"
          />
        )}

        {!csRequired && (
          <Actions>
            <Button
              id="registration-submit-button"
              type="submit"
              tone="brandAccent"
              loading={isProcessingForm}
            >
              {t('Create new account')}
            </Button>
            {isAccountSwitchingAvailable ? (
              <ButtonLink
                onClick={() =>
                  trackNavClicked
                    ? trackNavClicked({ linkText: 'cancel' })
                    : undefined
                }
                href={urlResolver({ path: URL_ACCOUNT_SELECT })}
                variant="transparent"
              >
                {t('Cancel')}
              </ButtonLink>
            ) : null}
          </Actions>
        )}
        {!isAccountSwitchingAvailable && (
          <Text>
            {t('Looking for a job message')}{' '}
            <TextLink
              href={JOB_SEARCH_SITE[site]}
              onClick={() =>
                trackNavClicked
                  ? trackNavClicked({ linkText: 'SEEK job search' })
                  : undefined
              }
            >
              {t('SEEK job search')}
            </TextLink>
          </Text>
        )}
      </Stack>
    </form>
  );
};

export default () => (
  <RegistrationFormProvider>
    <RegistrationForm />
  </RegistrationFormProvider>
);
