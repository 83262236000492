
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IuC5g+C4geC4peC5ieC4quC4s+C5gOC4o+C5h+C4iOC5geC4peC5ieC4pyEiLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IuC5g+C4iuC5ieC4peC4tOC4h+C4geC5jOC5gOC4nuC4t+C5iOC4reC5gOC4m+C4tOC4lOC5g+C4iuC5ieC4h+C4suC4meC4muC4seC4jeC4iuC4teC4leC4seC4p+C5geC4l+C4meC4guC4reC4h+C4hOC4uOC4kyIsIlJlZ2lzdGVyIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiOiLguK3guLXguYDguKHguKXguILguK3guIfguYDguK3guYDguIjguJnguIvguLXguJfguLXguYjguKXguIfguJfguLDguYDguJrguLXguKLguJnguYTguKfguYkiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IuC5gOC4o+C4suC5hOC4lOC5ieC4quC5iOC4h+C4peC4tOC4h+C4geC5jOC4geC4suC4o+C5gOC4m+C4tOC4lOC5g+C4iuC5ieC4h+C4suC4meC5hOC4m+C4l+C4teC5iCIsIlNpZ24gb3V0IGFuZCBjbG9zZSBicm93c2VyIHRhYiBtZXNzYWdlIjoi4LiI4LiZ4LiB4Lin4LmI4Liy4Lia4Lix4LiN4LiK4Li14LiC4Lit4LiH4LiE4Li44LiT4LiI4Liw4LmE4LiU4LmJ4Lij4Lix4Lia4LiB4Liy4Lij4LmA4Lib4Li04LiU4LmD4LiK4LmJ4LiH4Liy4LiZIDxTdHJvbmc+PExpbms+4Lit4Lit4LiB4LiI4Liy4LiB4Lij4Liw4Lia4LiaPC9MaW5rPjwvU3Ryb25nPiDguIjguLLguIHguJnguLHguYnguJnguJvguLTguJTguYHguJfguYfguJrguYDguJrguKPguLLguKfguYzguYDguIvguK3guKPguYzguJnguLXguYkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IuC5g+C4geC4peC5ieC4quC4s+C5gOC4o+C5h+C4iOC5geC4peC5ieC4pyEiLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IuC5g+C4iuC5ieC4peC4tOC4h+C4geC5jOC5gOC4nuC4t+C5iOC4reC5gOC4m+C4tOC4lOC5g+C4iuC5ieC4h+C4suC4meC4muC4seC4jeC4iuC4teC4leC4seC4p+C5geC4l+C4meC4guC4reC4h+C4hOC4uOC4kyIsIlJlZ2lzdGVyIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiOiLguK3guLXguYDguKHguKXguILguK3guIfguYDguK3guYDguIjguJnguIvguLXguJfguLXguYjguKXguIfguJfguLDguYDguJrguLXguKLguJnguYTguKfguYkiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IuC5gOC4o+C4suC5hOC4lOC5ieC4quC5iOC4h+C4peC4tOC4h+C4geC5jOC4geC4suC4o+C5gOC4m+C4tOC4lOC5g+C4iuC5ieC4h+C4suC4meC5hOC4m+C4l+C4teC5iCIsIlNpZ24gb3V0IGFuZCBjbG9zZSBicm93c2VyIHRhYiBtZXNzYWdlIjoi4LiI4LiZ4LiB4Lin4LmI4Liy4Lia4Lix4LiN4LiK4Li14LiC4Lit4LiH4LiE4Li44LiT4LiI4Liw4LmE4LiU4LmJ4Lij4Lix4Lia4LiB4Liy4Lij4LmA4Lib4Li04LiU4LmD4LiK4LmJ4LiH4Liy4LiZIDxTdHJvbmc+PExpbms+4Lit4Lit4LiB4LiI4Liy4LiB4Lij4Liw4Lia4LiaPC9MaW5rPjwvU3Ryb25nPiDguIjguLLguIHguJnguLHguYnguJnguJvguLTguJTguYHguJfguYfguJrguYDguJrguKPguLLguKfguYzguYDguIvguK3guKPguYzguJnguLXguYkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkhhbXBpciBzZWxlc2FpIiwiTmVlZCB2ZXJpZmljYXRpb24gYWN0aW9uIHRvIGFjdGl2YXRlIG1lc3NhZ2UiOiJHdW5ha2FuIHRhdXRhbiB1bnR1ayBtZW5nYWt0aWZrYW4gYWt1biBhZ2Vuc2kgQW5kYS4iLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoiYWxhbWF0IGVtYWlsIHBlbmdndW5hIGFnZW5zaSB0ZXJkYWZ0YXIiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IkthbWkgYmFydSBzYWphIG1lbmdpcmltIGVtYWlsIHRhdXRhbiBha3RpdmFzaSBrZSIsIlNpZ24gb3V0IGFuZCBjbG9zZSBicm93c2VyIHRhYiBtZXNzYWdlIjoiSGluZ2dhIGFrdW4gQW5kYSBkaWFrdGlma2FuLCA8U3Ryb25nPjxMaW5rPmtlbHVhcjwvTGluaz48L1N0cm9uZz4sIGxhbHUgdHV0dXAgdGFiIGJyb3dzZXIgaW5pLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkhhbXBpciBzZWxlc2FpIiwiTmVlZCB2ZXJpZmljYXRpb24gYWN0aW9uIHRvIGFjdGl2YXRlIG1lc3NhZ2UiOiJHdW5ha2FuIHRhdXRhbiB1bnR1ayBtZW5nYWt0aWZrYW4gYWt1biBhZ2Vuc2kgQW5kYS4iLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoiYWxhbWF0IGVtYWlsIHBlbmdndW5hIGFnZW5zaSB0ZXJkYWZ0YXIiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IkthbWkgYmFydSBzYWphIG1lbmdpcmltIGVtYWlsIHRhdXRhbiBha3RpdmFzaSBrZSIsIlNpZ24gb3V0IGFuZCBjbG9zZSBicm93c2VyIHRhYiBtZXNzYWdlIjoiSGluZ2dhIGFrdW4gQW5kYSBkaWFrdGlma2FuLCA8U3Ryb25nPjxMaW5rPmtlbHVhcjwvTGluaz48L1N0cm9uZz4sIGxhbHUgdHV0dXAgdGFiIGJyb3dzZXIgaW5pLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IuC5g+C4geC4peC5ieC4quC4s+C5gOC4o+C5h+C4iOC5geC4peC5ieC4pyEiLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IuC5g+C4iuC5ieC4peC4tOC4h+C4geC5jOC5gOC4nuC4t+C5iOC4reC5gOC4m+C4tOC4lOC5g+C4iuC5ieC4h+C4suC4meC4muC4seC4jeC4iuC4teC4leC4seC4p+C5geC4l+C4meC4guC4reC4h+C4hOC4uOC4kyIsIlJlZ2lzdGVyIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiOiLguK3guLXguYDguKHguKXguILguK3guIfguYDguK3guYDguIjguJnguIvguLXguJfguLXguYjguKXguIfguJfguLDguYDguJrguLXguKLguJnguYTguKfguYkiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IuC5gOC4o+C4suC5hOC4lOC5ieC4quC5iOC4h+C4peC4tOC4h+C4geC5jOC4geC4suC4o+C5gOC4m+C4tOC4lOC5g+C4iuC5ieC4h+C4suC4meC5hOC4m+C4l+C4teC5iCIsIlNpZ24gb3V0IGFuZCBjbG9zZSBicm93c2VyIHRhYiBtZXNzYWdlIjoi4LiI4LiZ4LiB4Lin4LmI4Liy4Lia4Lix4LiN4LiK4Li14LiC4Lit4LiH4LiE4Li44LiT4LiI4Liw4LmE4LiU4LmJ4Lij4Lix4Lia4LiB4Liy4Lij4LmA4Lib4Li04LiU4LmD4LiK4LmJ4LiH4Liy4LiZIDxTdHJvbmc+PExpbms+4Lit4Lit4LiB4LiI4Liy4LiB4Lij4Liw4Lia4LiaPC9MaW5rPjwvU3Ryb25nPiDguIjguLLguIHguJnguLHguYnguJnguJvguLTguJTguYHguJfguYfguJrguYDguJrguKPguLLguKfguYzguYDguIvguK3guKPguYzguJnguLXguYkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IuC5g+C4geC4peC5ieC4quC4s+C5gOC4o+C5h+C4iOC5geC4peC5ieC4pyEiLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IuC5g+C4iuC5ieC4peC4tOC4h+C4geC5jOC5gOC4nuC4t+C5iOC4reC5gOC4m+C4tOC4lOC5g+C4iuC5ieC4h+C4suC4meC4muC4seC4jeC4iuC4teC4leC4seC4p+C5geC4l+C4meC4guC4reC4h+C4hOC4uOC4kyIsIlJlZ2lzdGVyIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiOiLguK3guLXguYDguKHguKXguILguK3guIfguYDguK3guYDguIjguJnguIvguLXguJfguLXguYjguKXguIfguJfguLDguYDguJrguLXguKLguJnguYTguKfguYkiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IuC5gOC4o+C4suC5hOC4lOC5ieC4quC5iOC4h+C4peC4tOC4h+C4geC5jOC4geC4suC4o+C5gOC4m+C4tOC4lOC5g+C4iuC5ieC4h+C4suC4meC5hOC4m+C4l+C4teC5iCIsIlNpZ24gb3V0IGFuZCBjbG9zZSBicm93c2VyIHRhYiBtZXNzYWdlIjoi4LiI4LiZ4LiB4Lin4LmI4Liy4Lia4Lix4LiN4LiK4Li14LiC4Lit4LiH4LiE4Li44LiT4LiI4Liw4LmE4LiU4LmJ4Lij4Lix4Lia4LiB4Liy4Lij4LmA4Lib4Li04LiU4LmD4LiK4LmJ4LiH4Liy4LiZIDxTdHJvbmc+PExpbms+4Lit4Lit4LiB4LiI4Liy4LiB4Lij4Liw4Lia4LiaPC9MaW5rPjwvU3Ryb25nPiDguIjguLLguIHguJnguLHguYnguJnguJvguLTguJTguYHguJfguYfguJrguYDguJrguKPguLLguKfguYzguYDguIvguK3guKPguYzguJnguLXguYkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkhhbXBpciBzZWxlc2FpIiwiTmVlZCB2ZXJpZmljYXRpb24gYWN0aW9uIHRvIGFjdGl2YXRlIG1lc3NhZ2UiOiJHdW5ha2FuIHRhdXRhbiB1bnR1ayBtZW5nYWt0aWZrYW4gYWt1biBhZ2Vuc2kgQW5kYS4iLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoiYWxhbWF0IGVtYWlsIHBlbmdndW5hIGFnZW5zaSB0ZXJkYWZ0YXIiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IkthbWkgYmFydSBzYWphIG1lbmdpcmltIGVtYWlsIHRhdXRhbiBha3RpdmFzaSBrZSIsIlNpZ24gb3V0IGFuZCBjbG9zZSBicm93c2VyIHRhYiBtZXNzYWdlIjoiSGluZ2dhIGFrdW4gQW5kYSBkaWFrdGlma2FuLCA8U3Ryb25nPjxMaW5rPmtlbHVhcjwvTGluaz48L1N0cm9uZz4sIGxhbHUgdHV0dXAgdGFiIGJyb3dzZXIgaW5pLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkhhbXBpciBzZWxlc2FpIiwiTmVlZCB2ZXJpZmljYXRpb24gYWN0aW9uIHRvIGFjdGl2YXRlIG1lc3NhZ2UiOiJHdW5ha2FuIHRhdXRhbiB1bnR1ayBtZW5nYWt0aWZrYW4gYWt1biBhZ2Vuc2kgQW5kYS4iLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoiYWxhbWF0IGVtYWlsIHBlbmdndW5hIGFnZW5zaSB0ZXJkYWZ0YXIiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IkthbWkgYmFydSBzYWphIG1lbmdpcmltIGVtYWlsIHRhdXRhbiBha3RpdmFzaSBrZSIsIlNpZ24gb3V0IGFuZCBjbG9zZSBicm93c2VyIHRhYiBtZXNzYWdlIjoiSGluZ2dhIGFrdW4gQW5kYSBkaWFrdGlma2FuLCA8U3Ryb25nPjxMaW5rPmtlbHVhcjwvTGluaz48L1N0cm9uZz4sIGxhbHUgdHV0dXAgdGFiIGJyb3dzZXIgaW5pLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiVXNlIHRoZSBsaW5rIHRvIGFjdGl2YXRlIHlvdXIgYWdlbmN5IGFjY291bnQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlJ3ZlIHNlbnQgYW4gYWN0aXZhdGlvbiBsaW5rIHRvIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJVbnRpbCB5b3VyIGFjY291bnQgaXMgYWN0aXZhdGVkLCA8U3Ryb25nPjxMaW5rPnNpZ24gb3V0PC9MaW5rPjwvU3Ryb25nPiwgdGhlbiBjbG9zZSB0aGlzIGJyb3dzZXIgdGFiLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IlvhuqzGmm3MgsO2w7bDtsWh4bmvIMaMw7bDtsO24LiB4Li14LmJ4bq94bq94bq9IV0iLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IlvHmcWh4bq94bq94bq9IOG5r+G4qeG6veG6veG6vSDGmsOsw6zDrOC4geC4teC5icS3IOG5r8O2w7bDtiDEg8SDxIPDp+G5r8Osw6zDrOG5vcSDxIPEg+G5r+G6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPEo+G6veG6veG6veC4geC4teC5icOnw73DvcO9IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8uXSIsIlJlZ2lzdGVyIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiOiJb4bmv4bip4bq94bq94bq9IMWZ4bq94bq94bq9xKPDrMOsw6zFoeG5r+G6veG6veG6vcWZ4bq94bq94bq9xowgxIPEg8SDxKPhur3hur3hur3guIHguLXguYnDp8O9w73DvSDHmseax5rFoeG6veG6veG6vcWZIOG6veG6veG6vW3MgsSDxIPEg8Osw6zDrMaaIMSDxIPEg8aMxozFmeG6veG6veG6vcWhxaFdIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiJbxbThur3hur3hur0n4bm94bq94bq94bq9IMWh4bq94bq94bq94LiB4Li14LmJ4bmvIMSDxIPEg+C4geC4teC5iSDEg8SDxIPDp+G5r8Osw6zDrOG5vcSDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDGmsOsw6zDrOC4geC4teC5icS3IOG5r8O2w7bDtl0iLCJTaWduIG91dCBhbmQgY2xvc2UgYnJvd3NlciB0YWIgbWVzc2FnZSI6IlvHmeC4geC4teC5ieG5r8Osw6zDrMaaIMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8gw6zDrMOsxaEgxIPEg8SDw6fhua/DrMOsw6zhub3Eg8SDxIPhua/hur3hur3hur3GjCwgPFN0cm9uZz48TGluaz7FocOsw6zDrMSj4LiB4Li14LmJIMO2w7bDtseax5rHmuG5rzwvTGluaz48L1N0cm9uZz4sIOG5r+G4qeG6veG6veG6veC4geC4teC5iSDDp8aaw7bDtsO2xaHhur3hur3hur0g4bmv4bipw6zDrMOsxaEgw5/FmcO2w7bDtsW1xaHhur3hur3hur3FmSDhua/Eg8SDxIPDny5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IlvhuqzGmm3MgsO2w7bDtsWh4bmvIMaMw7bDtsO24LiB4Li14LmJ4bq94bq94bq9IV0iLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IlvHmcWh4bq94bq94bq9IOG5r+G4qeG6veG6veG6vSDGmsOsw6zDrOC4geC4teC5icS3IOG5r8O2w7bDtiDEg8SDxIPDp+G5r8Osw6zDrOG5vcSDxIPEg+G5r+G6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPEo+G6veG6veG6veC4geC4teC5icOnw73DvcO9IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8uXSIsIlJlZ2lzdGVyIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiOiJb4bmv4bip4bq94bq94bq9IMWZ4bq94bq94bq9xKPDrMOsw6zFoeG5r+G6veG6veG6vcWZ4bq94bq94bq9xowgxIPEg8SDxKPhur3hur3hur3guIHguLXguYnDp8O9w73DvSDHmseax5rFoeG6veG6veG6vcWZIOG6veG6veG6vW3MgsSDxIPEg8Osw6zDrMaaIMSDxIPEg8aMxozFmeG6veG6veG6vcWhxaFdIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiJbxbThur3hur3hur0n4bm94bq94bq94bq9IMWh4bq94bq94bq94LiB4Li14LmJ4bmvIMSDxIPEg+C4geC4teC5iSDEg8SDxIPDp+G5r8Osw6zDrOG5vcSDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDGmsOsw6zDrOC4geC4teC5icS3IOG5r8O2w7bDtl0iLCJTaWduIG91dCBhbmQgY2xvc2UgYnJvd3NlciB0YWIgbWVzc2FnZSI6IlvHmeC4geC4teC5ieG5r8Osw6zDrMaaIMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8gw6zDrMOsxaEgxIPEg8SDw6fhua/DrMOsw6zhub3Eg8SDxIPhua/hur3hur3hur3GjCwgPFN0cm9uZz48TGluaz7FocOsw6zDrMSj4LiB4Li14LmJIMO2w7bDtseax5rHmuG5rzwvTGluaz48L1N0cm9uZz4sIOG5r+G4qeG6veG6veG6veC4geC4teC5iSDDp8aaw7bDtsO2xaHhur3hur3hur0g4bmv4bipw6zDrMOsxaEgw5/FmcO2w7bDtsW1xaHhur3hur3hur3FmSDhua/Eg8SDxIPDny5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    