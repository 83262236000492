import {
  Box,
  Text,
  Column,
  Columns,
  Stack,
  Inline,
  IconSubCategory,
  Hidden,
  TextLink,
} from 'braid-design-system';

import type { accountPartsFragment } from 'src/graphql/generated';
import type { PendingAccountEmailStatuses } from 'src/views/pages/SelectAccountPage/SelectAccountPage';
import AccountActionButton from 'src/views/pages/SelectAccountPage/components/AccountSegment/AccountActionButton/AccountActionButton';
import AccountStatusIndicator from 'src/views/pages/SelectAccountPage/components/AccountSegment/AccountStatusIndicator/AccountStatusIndicator';
import { isActiveAccount } from 'src/views/pages/SelectAccountPage/helpers.ts';

export type Props = {
  option: accountPartsFragment;
  handleActivateAccount?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    node: accountPartsFragment,
  ) => void;
  pendingAccountEmailStatuses?: PendingAccountEmailStatuses;
};

export const AccountItem = ({
  option,
  handleActivateAccount,
  pendingAccountEmailStatuses,
}: Props) => {
  const hasChildren = 'childAccounts' in option;

  const displayAccountId = (
    hirerAccountId: string,
    billingId?: string | null,
  ): string => billingId?.replace(/^0+/, '') ?? hirerAccountId;

  const isActive = isActiveAccount(option);

  return (
    <Columns space="small" key={option?.hirerAccountId} collapseBelow="tablet">
      <Column width="1/2">
        <Columns space="small">
          <Column width="content">
            <Box paddingLeft="xxsmall">
              <Inline space="xxsmall" alignY="center">
                {!hasChildren && (
                  <Box paddingLeft="medium">
                    <Text icon={<IconSubCategory />} />
                  </Box>
                )}
                <AccountStatusIndicator isActive={isActive} />
              </Inline>
            </Box>
          </Column>
          <Column>
            <Stack space="small">
              <Text>
                {option.redirectUrl && isActive ? (
                  <TextLink
                    href={option.redirectUrl}
                    id={`${option.hirerAccountId}-selectable-link`}
                  >
                    {option.name}
                  </TextLink>
                ) : (
                  option.name
                )}
              </Text>
              <Hidden above="tablet">
                <Text>
                  {displayAccountId(option.hirerAccountId, option.billingId)}
                </Text>
              </Hidden>
            </Stack>
          </Column>
        </Columns>
      </Column>
      <Hidden below="tablet">
        <Column>
          <Text>
            {displayAccountId(option.hirerAccountId, option.billingId)}
          </Text>
        </Column>
      </Hidden>
      {handleActivateAccount ? (
        <Column width="content">
          <Box marginRight="xsmall" textAlign={{ tablet: 'right' }}>
            <AccountActionButton
              accountNode={option}
              handleActivateAccount={handleActivateAccount}
              pendingAccountEmailStatus={
                pendingAccountEmailStatuses &&
                pendingAccountEmailStatuses[option.hirerAccountId]
              }
            />
          </Box>
        </Column>
      ) : null}
    </Columns>
  );
};
