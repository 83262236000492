import type {
  ActionGroupFragment,
  BannerInlineFragment,
} from '@seek/cmsu-cms-connect';
import {
  type BlockItemRenderers,
  type BannerRenderers,
  blockRenderers,
  bannerRenderers,
  CustomComponentRenderer,
  ActionRenderer,
  Heading,
  Asset,
  Paragraph,
} from '@seek/cmsu-components';
import { ButtonLink } from '@seek/cmsu-components/src/modules/ActionGroup/ButtonLink';
import { ImageLink } from '@seek/cmsu-components/src/modules/ActionGroup/ImageLink';
import { TextLink } from '@seek/cmsu-components/src/modules/ActionGroup/TextLink';
import { AssetType } from '@seek/cmsu-components/src/modules/Asset/Asset';
import { Box, Column, Columns, Inline, Stack } from 'braid-design-system';
import { Helmet } from 'react-helmet';

import { HeroBanner } from './HomePage/components/HeroBanner/HeroBanner';

export const blockItemRenderers: BlockItemRenderers = {
  ...blockRenderers.hirer,
  // @ts-ignore
  // For context on why we are having to do this see this thread: https://seekchat.slack.com/archives/C041WNVA1SS/p1687932855347629
  CBanner: ({ banner_items }) => {
    const { actionGroup, heading, paragraph, reverse, image } =
      banner_items as Pick<
        BannerInlineFragment,
        'reverse' | 'image' | 'heading' | 'paragraph' | 'actionGroup'
      >;

    const { actions, inline } = actionGroup as Pick<
      ActionGroupFragment,
      'actions' | 'inline'
    > & {
      size?: string;
    };

    const filtered = actions.filter(
      (action) =>
        !(
          action.link?.to?.__typename === 'ExternalUrl' &&
          action.link.to.externalUrl === '-'
        ),
    );

    if (filtered.length === 0) {
      return null;
    }

    return (
      <Box data-testid="inlineBannerTestId">
        <Columns
          space={{ mobile: 'gutter', tablet: 'xlarge' }}
          alignY="center"
          collapseBelow="tablet"
          reverse={reverse || false}
        >
          <Column>
            {image && (
              <Asset
                assetType={AssetType.INLINE_BANNER}
                {...image}
                style={{ maxHeight: '300px' }}
              />
            )}
          </Column>
          <Column>
            <Stack space="gutter">
              {heading && <Heading {...heading} />}
              {paragraph && (
                <Paragraph
                  content={paragraph.Paragraph_text?.raw}
                  {...paragraph}
                />
              )}
              {actionGroup && (
                <Inline
                  space="gutter"
                  alignY="center"
                  collapseBelow="tablet"
                  align={inline?.align || 'left'}
                >
                  {filtered.map(
                    (action) =>
                      action.__typename && (
                        <ActionRenderer
                          key={action.id}
                          action={action as Required<typeof action>}
                          renderers={{
                            CTextLink: (props) => <TextLink {...props} />,
                            CButtonLink: (props) => <ButtonLink {...props} />,
                            CImageLink: (props) => <ImageLink {...props} />,
                          }}
                        />
                      ),
                  )}
                </Inline>
              )}
            </Stack>
          </Column>
        </Columns>
      </Box>
    );
  },
  CActionGroup: ({ actions, inline }) => {
    const filtered = actions.filter(
      (action) =>
        !(
          action.link?.to?.__typename === 'ExternalUrl' &&
          action.link.to.externalUrl === '-'
        ),
    );

    if (filtered.length === 0) {
      return null;
    }

    return (
      <Inline
        space="gutter"
        alignY="center"
        collapseBelow="tablet"
        align={inline?.align || 'left'}
      >
        {filtered.map(
          (action) =>
            action.__typename && (
              <ActionRenderer
                key={action.id}
                action={action as Required<typeof action>}
                renderers={{
                  CTextLink: (props) => <TextLink {...props} />,
                  CButtonLink: (props) => <ButtonLink {...props} />,
                  CImageLink: (props) => <ImageLink {...props} />,
                }}
              />
            ),
        )}
      </Inline>
    );
  },
  CCustomComponent: ({ data }, additionalData) =>
    data ? (
      <CustomComponentRenderer
        data={data}
        renderers={{
          ...blockRenderers.customComponentRenderers,
        }}
        additionalData={additionalData}
      />
    ) : null,
};

export const customBannerRenderers: BannerRenderers = {
  ...bannerRenderers.universal,
  CBannerShopfront: (banner) => (
    <>
      <Helmet
        link={[{ rel: 'preload', as: 'image', href: banner.image.url }]}
      />
      <HeroBanner {...banner} footnotes={banner.BannerShopfront_footnotes} />
    </>
  ),
};
