import {
  ENV_DEV,
  ENV_PRODUCTION,
  ENV_STAGING,
} from 'src/constants/environments';
import { FEATURE_CMS_PAGE_CONTENT } from 'src/modules/FeatureToggler/constants';
import type { FeatureConfig } from 'src/modules/FeatureToggler/types';

export const cmsPageContentFeature: FeatureConfig = {
  name: FEATURE_CMS_PAGE_CONTENT,
  value: {
    [ENV_DEV]: {
      enabled: true,
    },
    [ENV_STAGING]: {
      enabled: true,
    },
    [ENV_PRODUCTION]: {
      enabled: true,
    },
  },
  toFeature({ environment, site }) {
    const dict = this.value! as any;
    const val = dict[environment][site]
      ? dict[environment][site]
      : dict[environment];

    return {
      name: this.name,
      enabled: Boolean(val.enabled),
    };
  },
};
