// local page URLs
export const URL_REGISTER_AGENCY_COMPLETE = '/register/agency-complete';
export const URL_USER_VERIFICATION_COMPLETE =
  '/account/user-verification-complete';

export const URL_COMPLETE_USER_REGISTRATION =
  '/registration/complete-user-registration';
export const URL_COMPLETE_USER_REGISTRATION_ERROR = `${URL_COMPLETE_USER_REGISTRATION}/error`;
export const URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION =
  '/registration/complete-additional-advertiser-registration';
export const URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION_ERROR = `${URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION}/error`;
