import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type {
  RegisterNewUserInput,
  RegisterPayload,
  RegisterNewUserResult,
} from 'src/modules/ApiClient/types';

export const mutation = gql`
  mutation RegisterNewUser($input: RegisterNewUserInput!) {
    registerNewUser(input: $input) {
      seekId
      redirectUrl
    }
  }
`;

export type RegisterNewUser = (
  input: RegisterNewUserInput,
) => Promise<RegisterPayload>;

const registerNewUser: RegisterNewUser = async (input) => {
  const graphqlClient = getClient();
  const {
    data: {
      registerNewUser: { seekId, redirectUrl },
    },
  } = (await graphqlClient.mutate({
    variables: { input },
    mutation,
  })) as RegisterNewUserResult;

  return {
    seekId,
    redirectUrl,
  };
};

export default registerNewUser;
