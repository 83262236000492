import { PageBlock, Stack, Heading, Box } from 'braid-design-system';

import type { BasePageLayoutProps } from 'src/types';
import { ResponsiveImage } from 'src/views/shared/ResponsiveImage/ResponsiveImage';

interface Props extends BasePageLayoutProps {
  heading: string;
  image?: JSX.Element;
}

export const EmptyPageLayout = ({ children, image, heading }: Props) => (
  <PageBlock width="small">
    <Stack space="large" align="center">
      {image && <ResponsiveImage image={image} />}
      <Heading level="2">{heading}</Heading>
      <Box textAlign="center">{children}</Box>
    </Stack>
  </PageBlock>
);
