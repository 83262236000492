
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiLguILguK3guJrguITguLjguJPguKrguLPguKvguKPguLHguJrguIHguLLguKPguKLguLfguJnguKLguLHguJnguK3guLXguYDguKHguKXguILguK3guIfguITguLjguJMiLCJSZXN1bWUgcG9zdGluZyBqb2IgYWQgbWVzc2FnZSI6IuC4m+C4tOC4lOC5geC4l+C5h+C4muC4meC4teC5ieC5gOC4nuC4t+C5iOC4reC4peC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4k+C4leC5iOC4reC5hOC4myJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiLguILguK3guJrguITguLjguJPguKrguLPguKvguKPguLHguJrguIHguLLguKPguKLguLfguJnguKLguLHguJnguK3guLXguYDguKHguKXguILguK3guIfguITguLjguJMiLCJSZXN1bWUgcG9zdGluZyBqb2IgYWQgbWVzc2FnZSI6IuC4m+C4tOC4lOC5geC4l+C5h+C4muC4meC4teC5ieC5gOC4nuC4t+C5iOC4reC4peC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4k+C4leC5iOC4reC5hOC4myJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUZXJpbWEga2FzaWggdGVsYWggbWVuZ2tvbmZpcm1hc2kgZW1haWwgQW5kYSIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiVHV0dXAgdGFiIGluaSB1bnR1ayBtZWxhbmp1dGthbiBtZW1hc2FuZyBpa2xhbiBsb3dvbmdhbiBwZWtlcmphYW4gQW5kYS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUZXJpbWEga2FzaWggdGVsYWggbWVuZ2tvbmZpcm1hc2kgZW1haWwgQW5kYSIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiVHV0dXAgdGFiIGluaSB1bnR1ayBtZWxhbmp1dGthbiBtZW1hc2FuZyBpa2xhbiBsb3dvbmdhbiBwZWtlcmphYW4gQW5kYS4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiLguILguK3guJrguITguLjguJPguKrguLPguKvguKPguLHguJrguIHguLLguKPguKLguLfguJnguKLguLHguJnguK3guLXguYDguKHguKXguILguK3guIfguITguLjguJMiLCJSZXN1bWUgcG9zdGluZyBqb2IgYWQgbWVzc2FnZSI6IuC4m+C4tOC4lOC5geC4l+C5h+C4muC4meC4teC5ieC5gOC4nuC4t+C5iOC4reC4peC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4k+C4leC5iOC4reC5hOC4myJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiLguILguK3guJrguITguLjguJPguKrguLPguKvguKPguLHguJrguIHguLLguKPguKLguLfguJnguKLguLHguJnguK3guLXguYDguKHguKXguILguK3guIfguITguLjguJMiLCJSZXN1bWUgcG9zdGluZyBqb2IgYWQgbWVzc2FnZSI6IuC4m+C4tOC4lOC5geC4l+C5h+C4muC4meC4teC5ieC5gOC4nuC4t+C5iOC4reC4peC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4k+C4leC5iOC4reC5hOC4myJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUZXJpbWEga2FzaWggdGVsYWggbWVuZ2tvbmZpcm1hc2kgZW1haWwgQW5kYSIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiVHV0dXAgdGFiIGluaSB1bnR1ayBtZWxhbmp1dGthbiBtZW1hc2FuZyBpa2xhbiBsb3dvbmdhbiBwZWtlcmphYW4gQW5kYS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUZXJpbWEga2FzaWggdGVsYWggbWVuZ2tvbmZpcm1hc2kgZW1haWwgQW5kYSIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiVHV0dXAgdGFiIGluaSB1bnR1ayBtZWxhbmp1dGthbiBtZW1hc2FuZyBpa2xhbiBsb3dvbmdhbiBwZWtlcmphYW4gQW5kYS4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MgZm9yIGNvbmZpcm1pbmcgeW91ciBlbWFpbCIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiQ2xvc2UgdGhpcyB0YWIgdG8gY29udGludWUgcG9zdGluZyB5b3VyIGpvYiBhZC4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJb4bmu4bipxIPEg8SD4LiB4Li14LmJxLfFoSDGksO2w7bDtsWZIMOnw7bDtsO24LiB4Li14LmJxpLDrMOsw6zFmW3MgsOsw6zDrOC4geC4teC5icSjIMO9w73DvcO2w7bDtseax5rHmsWZIOG6veG6veG6vW3MgsSDxIPEg8Osw6zDrMaaXSIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiW8OHxprDtsO2w7bFoeG6veG6veG6vSDhua/huKnDrMOsw6zFoSDhua/Eg8SDxIPDnyDhua/DtsO2w7Ygw6fDtsO2w7bguIHguLXguYnhua/DrMOsw6zguIHguLXguYnHmseax5rhur3hur3hur0gxqXDtsO2w7bFoeG5r8Osw6zDrOC4geC4teC5icSjIMO9w73DvcO2w7bDtseax5rHmsWZIMS1w7bDtsO2w58gxIPEg8SDxowuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJb4bmu4bipxIPEg8SD4LiB4Li14LmJxLfFoSDGksO2w7bDtsWZIMOnw7bDtsO24LiB4Li14LmJxpLDrMOsw6zFmW3MgsOsw6zDrOC4geC4teC5icSjIMO9w73DvcO2w7bDtseax5rHmsWZIOG6veG6veG6vW3MgsSDxIPEg8Osw6zDrMaaXSIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiW8OHxprDtsO2w7bFoeG6veG6veG6vSDhua/huKnDrMOsw6zFoSDhua/Eg8SDxIPDnyDhua/DtsO2w7Ygw6fDtsO2w7bguIHguLXguYnhua/DrMOsw6zguIHguLXguYnHmseax5rhur3hur3hur0gxqXDtsO2w7bFoeG5r8Osw6zDrOC4geC4teC5icSjIMO9w73DvcO2w7bDtseax5rHmsWZIMS1w7bDtsO2w58gxIPEg8SDxowuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    