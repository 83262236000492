import { createDatadogLogger } from 'src/modules/Datadog/datadogRUM';
import type { Environment } from 'src/types';

export interface LogContext extends Record<string, unknown> {
  message?: string;
  activity?: string;
}

export interface ErrorContext extends LogContext {
  error?: Error;
}

type ApplicationLogContextKeys = 'advertiserId' | 'userId';
export type ApplicationLogContext = Record<ApplicationLogContextKeys, unknown>;

export interface Logger {
  info: (message: string, context: LogContext) => void;
  error: (message: string, context: ErrorContext) => void;
  configureApplicationContext: (
    context: Partial<ApplicationLogContext>,
  ) => void;
}

export interface LoggerConfig {
  environment: Environment;
  buildVersion?: string;
  country?: string;
}

const createDefaultLogger = (): Logger => ({
  // eslint-disable-next-line no-console
  info: console.info,
  // eslint-disable-next-line no-console
  error: console.error,
  configureApplicationContext: () => {},
});

export const initialiseLogger = ({
  environment,
  buildVersion,
  country,
}: LoggerConfig) => {
  switch (environment) {
    case 'production':
      createDatadogLogger({ environment, buildVersion, country });
      break;
    default:
      createDefaultLogger();
  }
};
