
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiLguJXguLTguJTguJXguYjguK3guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYzguYTguJTguYnguJfguLXguYgiLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IuC4leC4tOC4lOC4leC5iOC4reC5gOC4o+C4sjxMaW5rPiDguYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L0xpbms+IiwiTmVlZCBoZWxwIjoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiE4Lin4Liy4Lih4LiK4LmI4Lin4Lii4LmA4Lir4Lil4Li34LitPyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiLguJXguLTguJTguJXguYjguK3guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYzguYTguJTguYnguJfguLXguYgiLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IuC4leC4tOC4lOC4leC5iOC4reC5gOC4o+C4sjxMaW5rPiDguYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L0xpbms+IiwiTmVlZCBoZWxwIjoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiE4Lin4Liy4Lih4LiK4LmI4Lin4Lii4LmA4Lir4Lil4Li34LitPyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJIdWJ1bmdpIHRpbSBsYXlhbmFuIHBlbGFuZ2dhbiBrYW1pIGRpIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJIdWJ1bmdpIDxMaW5rPnRpbSBsYXlhbmFuIHBlbGFuZ2dhbjwvTGluaz4ga2FtaSIsIk5lZWQgaGVscCI6IkJ1dHVoIGJhbnR1YW4/In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJIdWJ1bmdpIHRpbSBsYXlhbmFuIHBlbGFuZ2dhbiBrYW1pIGRpIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJIdWJ1bmdpIDxMaW5rPnRpbSBsYXlhbmFuIHBlbGFuZ2dhbjwvTGluaz4ga2FtaSIsIk5lZWQgaGVscCI6IkJ1dHVoIGJhbnR1YW4/In0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiLguJXguLTguJTguJXguYjguK3guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYzguYTguJTguYnguJfguLXguYgiLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IuC4leC4tOC4lOC4leC5iOC4reC5gOC4o+C4sjxMaW5rPiDguYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L0xpbms+IiwiTmVlZCBoZWxwIjoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiE4Lin4Liy4Lih4LiK4LmI4Lin4Lii4LmA4Lir4Lil4Li34LitPyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiLguJXguLTguJTguJXguYjguK3guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYzguYTguJTguYnguJfguLXguYgiLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IuC4leC4tOC4lOC4leC5iOC4reC5gOC4o+C4sjxMaW5rPiDguYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L0xpbms+IiwiTmVlZCBoZWxwIjoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiE4Lin4Liy4Lih4LiK4LmI4Lin4Lii4LmA4Lir4Lil4Li34LitPyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJIdWJ1bmdpIHRpbSBsYXlhbmFuIHBlbGFuZ2dhbiBrYW1pIGRpIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJIdWJ1bmdpIDxMaW5rPnRpbSBsYXlhbmFuIHBlbGFuZ2dhbjwvTGluaz4ga2FtaSIsIk5lZWQgaGVscCI6IkJ1dHVoIGJhbnR1YW4/In0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJIdWJ1bmdpIHRpbSBsYXlhbmFuIHBlbGFuZ2dhbiBrYW1pIGRpIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJIdWJ1bmdpIDxMaW5rPnRpbSBsYXlhbmFuIHBlbGFuZ2dhbjwvTGluaz4ga2FtaSIsIk5lZWQgaGVscCI6IkJ1dHVoIGJhbnR1YW4/In0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJDb250YWN0IG91ciBjdXN0b21lciBzZXJ2aWNlIHRlYW0gb24iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IkNvbnRhY3Qgb3VyIDxMaW5rPmN1c3RvbWVyIHNlcnZpY2UgdGVhbTwvTGluaz4iLCJOZWVkIGhlbHAiOiJOZWVkIGhlbHA/In0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJbw4fDtsO2w7bguIHguLXguYnhua/Eg8SDxIPDp+G5ryDDtsO2w7bHmseax5rFmSDDp8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaHhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23MgiDDtsO2w7bguIHguLXguYldIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJbw4fDtsO2w7bguIHguLXguYnhua/Eg8SDxIPDp+G5ryDDtsO2w7bHmseax5rFmSA8TGluaz7Dp8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaHhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23MgjwvTGluaz5dIiwiTmVlZCBoZWxwIjoiW8OR4bq94bq94bq94bq94bq94bq9xowg4bip4bq94bq94bq9xprGpT9dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJbw4fDtsO2w7bguIHguLXguYnhua/Eg8SDxIPDp+G5ryDDtsO2w7bHmseax5rFmSDDp8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaHhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23MgiDDtsO2w7bguIHguLXguYldIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJbw4fDtsO2w7bguIHguLXguYnhua/Eg8SDxIPDp+G5ryDDtsO2w7bHmseax5rFmSA8TGluaz7Dp8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaHhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23MgjwvTGluaz5dIiwiTmVlZCBoZWxwIjoiW8OR4bq94bq94bq94bq94bq94bq9xowg4bip4bq94bq94bq9xprGpT9dIn0=!"
        )
      )
      });
  
      export { translations as default };
    