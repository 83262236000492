import {
  URL_ERROR_FORBIDDEN,
  URL_ERROR_SERVER_ERROR,
  URL_ROOT,
} from '@seek/adv-constants';
import { useEffect } from 'react';

import {
  URL_COMPLETE_USER_REGISTRATION_ERROR,
  URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION_ERROR,
} from 'src/constants/page-urls';
import completeAdditionalAdvertiserRegistrationMutation from 'src/modules/ApiClient/hirerGraph/mutations/gql_completeAdditionalAdvertiserRegistration';
import completeUserRegistrationMutation from 'src/modules/ApiClient/hirerGraph/mutations/gql_completeUserRegistration';
import type {
  SecureLinkMutation,
  SecureLinkResponse,
} from 'src/modules/ApiClient/types';
import type {
  SecureLinkErrorType,
  SecureLinkFlow,
  SecureLinkValidationResponse,
} from 'src/types';
import { getQueryParams } from 'src/utils/queryParams';

export const getMutation = (flow: SecureLinkFlow): SecureLinkMutation => {
  switch (flow) {
    case 'completeUserRegistration':
      return completeUserRegistrationMutation;
    case 'CompleteAdditionalAdvertiserRegistration':
      return completeAdditionalAdvertiserRegistrationMutation;
  }
};

export const getErrorUrl = (
  flow: SecureLinkFlow,
  reason?: SecureLinkErrorType,
) => {
  const page =
    flow === 'completeUserRegistration'
      ? URL_COMPLETE_USER_REGISTRATION_ERROR
      : URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION_ERROR;

  return `${page}${typeof reason === 'string' ? `?reason=${reason}` : ``}`;
};

export const mapMutationResponse = (
  response: SecureLinkResponse | undefined,
): SecureLinkValidationResponse => {
  switch (response?.__typename) {
    case 'SecureLinkSuccessResponse':
      return {
        validationResult: 'success',
        redirectUri: response.redirectUri,
      };
    case 'SecureLinkUsedError':
      return {
        validationResult: 'used',
      };
    case 'SecureLinkExpiredError':
      return {
        validationResult: 'expired',
      };
  }
  return {
    validationResult: 'invalid',
  };
};

export default (flow: SecureLinkFlow) => {
  useEffect(() => {
    const { link } = getQueryParams();
    if (!link) {
      window.location.replace(URL_ROOT);
      return;
    }

    const fn = async () => {
      try {
        const response = mapMutationResponse(
          await getMutation(flow)({
            link,
          }),
        );

        if (response.validationResult === 'success') {
          window.location.replace(response.redirectUri);
          return;
        }

        window.location.replace(getErrorUrl(flow, response.validationResult));
      } catch (err) {
        if (err instanceof Error && err.message === 'Suspended user') {
          window.location.replace(URL_ERROR_FORBIDDEN);
          return;
        }

        return window.location.replace(URL_ERROR_SERVER_ERROR);
      }
    };

    fn();
  }, [flow]);
};
