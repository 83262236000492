import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type {
  Context,
  CurrentAdvertiserContext,
  GetContextResponse,
  UserContext,
} from 'src/modules/ApiClient/types';

export type GetContext = () => Promise<Context>;

const query = gql`
  query GetCurrentUser {
    currentUser {
      __typename
      ... on CurrentUserContext {
        id
      }
      ... on Error {
        message
      }
    }
    currentAdvertiser {
      ... on CurrentAdvertiserContext {
        __typename
        seekId
        advertiserId
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;

const getContext: GetContext = async () => {
  const graphqlClient = getClient();
  const result = await graphqlClient.query<GetContextResponse>({
    query,
  });

  const { currentUser: userResponse, currentAdvertiser: advertiserResponse } =
    result.data;

  let currentUser: UserContext | null = null;
  if (userResponse.__typename === 'CurrentUserContext') {
    currentUser = {
      ...userResponse,
    };
  }

  let currentAdvertiser: CurrentAdvertiserContext | null = null;
  if (advertiserResponse.__typename === 'CurrentAdvertiserContext') {
    currentAdvertiser = {
      ...advertiserResponse,
    };
  }

  return {
    currentUser,
    currentAdvertiser,
  };
};

export default getContext;
