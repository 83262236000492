import type { EmployerSiteName } from '@seek/adv-header-footer';
import { siteNames } from '@seek/melways-sites';
import { CUSTOMER_SERVICE_PHONE_NUMBER } from '@seek/shared-constants';
import { Strong } from 'braid-design-system';

export interface Props {
  site: EmployerSiteName;
}

export const CsPhoneNumber = ({ site }: Props) => {
  if (
    site === siteNames.employer.seek.au ||
    site === siteNames.employer.seek.nz
  ) {
    return <Strong>{CUSTOMER_SERVICE_PHONE_NUMBER[site]}</Strong>;
  }

  return null;
};
