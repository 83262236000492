import { createContext, useContext } from 'react';

import type { Feature } from 'src/modules/FeatureToggler/types';

interface ContextProps {
  features: Feature[];
  overrides: Feature[];
  setOverrides: (newOverrides: Feature[]) => void;
}

export const FeatureToggleContext = createContext<ContextProps>({
  features: [],
  overrides: [],
  setOverrides: () => {},
});

export const useFeatureToggleContext = () => useContext(FeatureToggleContext);
