import { useTranslations } from '@vocab/react';
import {
  Box,
  FieldLabel,
  TextField,
  Columns,
  Column,
  Dropdown,
  Stack,
} from 'braid-design-system';
import {
  type CountryCode,
  getExampleNumber,
  getPhoneCode,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import { type FormEvent, useEffect, useState } from 'react';

import type { Maybe } from 'src/types';
import translations from 'src/views/shared/forms/RegistrationForm/.vocab';

import { PhoneCountryMismatchAlert } from './PhoneCountryMismatchAlert';
import { allCountryCodes, seekCountryCodes } from './areaCodeData';
interface Props {
  id: string;
  label?: string;
  description?: string;
  onPhoneNumberChange: (value: Maybe<string>) => void;
  onPhoneNumberBlur: () => void;
  onPhoneNumberCountryCodeChange: (value: Maybe<string>) => void;
  message?: string;
  businessCountryCode: CountryCode;
}

export const PhoneNumber = ({
  id,
  label = 'Phone Number',
  description,
  onPhoneNumberChange,
  onPhoneNumberBlur,
  onPhoneNumberCountryCodeChange,
  message,
  businessCountryCode,
}: Props) => {
  const [phoneNumberCountryCode, setCountryCode] = useState<CountryCode>(
    businessCountryCode ?? 'AU',
  );
  const [nationalNumber, setNationalNumber] = useState('');
  const { t } = useTranslations(translations);

  const formattedList = [
    { label: t('SEEK countries'), data: seekCountryCodes },
    { label: t('All countries'), data: allCountryCodes },
  ];

  const callingCode = getPhoneCode(phoneNumberCountryCode);

  const getPlaceholder = () => {
    if (phoneNumberCountryCode) {
      return (
        getExampleNumber(phoneNumberCountryCode, examples)
          ?.formatInternational()
          ?.replace(`+${callingCode} `, '')
          ?.replace(/\s+/g, '') || ''
      );
    }
    return t('Enter phone number');
  };

  useEffect(() => {
    setCountryCode(businessCountryCode);
    onPhoneNumberCountryCodeChange(businessCountryCode);

    if (nationalNumber !== '') {
      onPhoneNumberChange(
        `${getPhoneCode(businessCountryCode)} ${nationalNumber}`,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCountryCode]);

  const handleNumberChange = (e: FormEvent<HTMLInputElement>) => {
    const enteredValue = e.currentTarget.value;
    if (!enteredValue) {
      onPhoneNumberChange('');
      setNationalNumber('');
      return;
    }

    if (
      Boolean(enteredValue.match(/\s\s+/g)) ||
      !enteredValue.match(/^[0-9\s]*$/)
    ) {
      // just numbers, no consecutive space
      return;
    }
    const pNumber = `${callingCode} ${enteredValue}`;

    onPhoneNumberChange(pNumber);
    setNationalNumber(enteredValue);
  };

  const handleCallingCodeChange = (e: FormEvent<HTMLSelectElement>) => {
    const selectedCountryCode = (e.target as HTMLSelectElement)
      .value as CountryCode;

    setCountryCode(selectedCountryCode);
    onPhoneNumberCountryCodeChange(selectedCountryCode);

    if (nationalNumber !== '') {
      onPhoneNumberChange(
        `${getPhoneCode(selectedCountryCode)} ${nationalNumber}`,
      );
    }
  };

  const handleClear = () => {
    onPhoneNumberChange('');
    setNationalNumber('');
  };

  const hasCountryMismatch = businessCountryCode !== phoneNumberCountryCode;

  return (
    <Box id={id}>
      <Stack space="medium">
        <Stack space="small">
          <FieldLabel
            label={label}
            description={description}
            htmlFor="phone-number"
          />
          <Columns space="small" collapseBelow="tablet">
            <Column>
              <Dropdown
                id="calling-code"
                aria-label="calling-code"
                onChange={(e) => handleCallingCodeChange(e)}
                value={phoneNumberCountryCode}
                placeholder={t('Select country code')}
                data={{ testid: 'calling-code' }}
              >
                {formattedList.map((item) => (
                  <optgroup label={item.label} key={item.label}>
                    {item.data.map((area, index) => (
                      <option key={index} value={area.countryCode}>
                        {area.name} ( +{area.callingCode} )
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Dropdown>
            </Column>
            <Column>
              <TextField
                disabled={!phoneNumberCountryCode}
                id="phone-number"
                aria-label="phone-number"
                prefix={`+${phoneNumberCountryCode ? callingCode : ''}`}
                type="text"
                placeholder={getPlaceholder()}
                data={{ testid: 'phone-number' }}
                value={nationalNumber}
                onChange={(e) => handleNumberChange(e)}
                onBlur={onPhoneNumberBlur}
                onClear={() => handleClear()}
                tone={message ? 'critical' : 'neutral'}
                message={message}
                reserveMessageSpace
              />
            </Column>
          </Columns>
        </Stack>
        {hasCountryMismatch && (
          <PhoneCountryMismatchAlert hasMismatch={hasCountryMismatch} />
        )}
      </Stack>
    </Box>
  );
};
