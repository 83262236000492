import { type Country, siteConfig } from '@seek/melways-sites';

import type { EmployerSiteName } from 'src/types';
import { flip } from 'src/utils/flip';

const countryCodeRecord: Record<Country, string> = {
  au: 'Australia',
  nz: 'New Zealand',
  hk: 'Hong Kong',
  th: 'Thailand',
  sg: 'Singapore',
  id: 'Indonesia',
  my: 'Malaysia',
  ph: 'Philippines',
};

const countryNameRecord: Record<string, Country> = flip(countryCodeRecord);

/**
 * Converts a country code to a full country name.
 * E.g., 'employer-seek-au' -> 'Australia'
 */
export const siteNameToCountryName = (siteName: EmployerSiteName) => {
  const country = siteConfig[siteName].classification.country;
  return countryCodeRecord[country];
};

/**
 * Converts a full country name to a country code. Returns 'undefined' if the country name is not found.
 * E.g., 'Australia' -> 'au'
 */
export const countryNameToCode = (name: string): Country | undefined =>
  countryNameRecord[name];
