import { useTrackView } from '@seek/cmsu-analytics';
import { useEffect, useState } from 'react';

import getContext from 'src/modules/ApiClient/hirerGraph/queries/gql_getContext';
import getHeaderFooterContext from 'src/modules/ApiClient/hirerGraph/queries/gql_getHeaderFooterContext';

export interface UserTrackingInfo {
  seekAdvertiserId: string;
  userId: string;
  hirerUserEmail: string;
  hirerUserFullName: string;
}

export interface useAuthenticatedViewTrackingProps {
  eventName?: string;
  additionalInfo?: Record<string, string>;
  triggerViewTracking?: boolean;
}

export const useAuthenticatedViewTracking = ({
  eventName,
  additionalInfo,
  triggerViewTracking = true,
}: useAuthenticatedViewTrackingProps) => {
  const [userInfo, setUserInfo] = useState<UserTrackingInfo>();
  const [viewTracked, setViewTracked] = useState(false);

  const trackViewFunction = useTrackView(eventName, {
    ...userInfo,
    ...additionalInfo,
  });

  useEffect(() => {
    const fetchData = async () => {
      const { currentAdvertiser, currentUser } = await getContext();
      const { user, identityContext } = await getHeaderFooterContext();
      return {
        seekAdvertiserId: currentAdvertiser ? currentAdvertiser.seekId : '',
        userId: currentUser ? currentUser.id : '',
        hirerUserEmail: identityContext ? identityContext.email : '',
        hirerUserFullName: user ? user.name : '',
      };
    };
    fetchData()
      .then((data) => setUserInfo(data))
      .catch();
  }, []);

  useEffect(() => {
    if (trackViewFunction && userInfo && !viewTracked && triggerViewTracking) {
      setViewTracked(true);
      trackViewFunction();
    }
  }, [userInfo, trackViewFunction, viewTracked, triggerViewTracking]);
  return [userInfo];
};

export default useAuthenticatedViewTracking;
