// graphQL
export const URL_HIRER_GRAPH = '/graphql';

// Switch between one of the following graph URLs when in development

// Local dev with production graph
// export const URL_HIRER_GRAPH_LOCAL = `https://talent.seek.com.au${URL_HIRER_GRAPH}`;

// Local dev with staging graph
export const URL_HIRER_GRAPH_STAGING = `https://talent.staging.seek.com.au${URL_HIRER_GRAPH}`;

// Local dev with local graph
export const URL_HIRER_GRAPH_LOCAL = `http://localhost:4000${URL_HIRER_GRAPH}`;
