import { URL_SIGNOUT } from '@seek/adv-constants';
import { useTranslations } from '@vocab/react';
import { Stack, Strong, Text, TextLink } from 'braid-design-system';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useTrackPageLoad from 'src/modules/AnalyticsTracker/useTrackPageLoad';
import { EmptyPageLayout } from 'src/views/layouts/EmptyPageLayout/EmptyPageLayout';
import { IconEmail } from 'src/views/shared/icons/IconEmail';

import translations from './.vocab';

const TRACKING_PAGE_DATA_REGISTRATION_COMPLETE = {
  page: 'registration-complete',
  siteSection: 'home',
  subSection: 'confirmation',
};

const AgencyRegistrationCompletePage = () => {
  const location = useLocation();
  const { t } = useTranslations(translations);

  useTrackPageLoad(TRACKING_PAGE_DATA_REGISTRATION_COMPLETE);
  useEffect(() => {
    const signOutCsUser = async () => {
      window.location.assign(URL_SIGNOUT);
    };

    window.setTimeout(signOutCsUser, 20000);
  });

  let email = t('Register agency user email address');

  if (location.state) {
    email = location.state.registeredUserDetails.email;
  }

  return (
    <EmptyPageLayout heading={t('Almost done')} image={<IconEmail />}>
      <Stack space="large">
        <Stack space="medium">
          <Text>
            {t('Sent activation email message')}{' '}
            <Strong data={{ 'dd-privacy': 'mask', 'hj-masked': true }}>
              {email}
            </Strong>
            .
          </Text>
          <Text>{t('Need verification action to activate message')}</Text>
        </Stack>

        <Text>
          {t('Sign out and close browser tab message', {
            Strong: (children) => <Strong>{children}</Strong>,
            Link: (children) => (
              <TextLink href={URL_SIGNOUT}>{children}</TextLink>
            ),
          })}
        </Text>
      </Stack>
    </EmptyPageLayout>
  );
};

export default AgencyRegistrationCompletePage;
