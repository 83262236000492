import { createValidator } from '@seek/validators-js';
import { validatePhoneNumberLength } from 'libphonenumber-js';

import translations from './.vocab';

const REGEX_PATTERN_NO_STARTING_SPACE = /^[^\s]/;

type Value = string;
interface ValidatorProps {
  fieldLabel: string;
}

/**
 * @name noStartingSpace
 * @description Validates a value against a white space character at the start of the value
 */
export const { noStartingSpace } = createValidator({
  test: ({ value }: { value: Value }) =>
    Boolean(value) === false || REGEX_PATTERN_NO_STARTING_SPACE.test(value),
  formatErrorMessages:
    (messages) =>
    ({ validatorProps }: { validatorProps: ValidatorProps }) =>
      messages['no-starting-space-error'].format({
        fieldLabel: validatorProps.fieldLabel,
      }),
  translations,
  validatorName: 'noStartingSpace',
});

/**
 * @name isValidSsoEmail
 * @description Validates an email address against validation rules set by Auth0
 * https://auth0.com/docs/user-profile#create-a-new-user
 */

export const { isValidSsoEmail } = createValidator({
  test: ({ value }: { value: Value }) => {
    if (value.includes('@')) {
      const emailParts = value.split('@');
      const emailUser = emailParts[0];
      const emailDomain = emailParts[1];

      return (
        Boolean(value) === false ||
        (emailUser.length <= 64 && emailDomain.length <= 256)
      );
    }
    return false;
  },
  formatErrorMessages: (messages) => (_fieldProps) =>
    messages['invalid-sso-email-address-error'].format(),
  translations,
  validatorName: 'isValidSsoEmail',
});

export const { isShortPhone } = createValidator({
  test: ({ value }: { value: Value }) => {
    const result = validatePhoneNumberLength(`+${value}`);
    return result !== 'TOO_SHORT';
  },
  formatErrorMessages: (messages) => () =>
    messages['too-short-phone-number-error'].format(),
  translations,
  validatorName: 'isShortPhone',
});

export const { isLongPhone } = createValidator({
  test: ({ value }: { value: Value }) => {
    const result = validatePhoneNumberLength(`+${value}`);
    return result !== 'TOO_LONG';
  },
  formatErrorMessages: (messages) => () =>
    messages['too-long-phone-number-error'].format(),
  translations,
  validatorName: 'isLongPhone',
});
