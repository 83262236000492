import type { Country } from 'src/graphql/generated';
import type { CountryMap } from 'src/views/shared/forms/RegistrationForm/PhoneNumber/areaCodeData.ts';

// These country codes are missing from external phone library (libphonenumber-js) and causing problems
export const filterUnsupportedCountries = (
  countries: Country[] | CountryMap[],
) =>
  countries.filter(
    (country) =>
      ['AQ', 'BV', 'TF', 'HM', 'PN', 'GS', 'UM'].indexOf(
        country.countryCode,
      ) === -1,
  );
