import { Text, Stack, Heading, PageBlock, Divider } from 'braid-design-system';
import { Fragment } from 'react';

import type { accountPartsFragment } from 'src/graphql/generated';
import type { AccountSelectionResults } from 'src/types';
import type { PendingAccountEmailStatuses } from 'src/views/pages/SelectAccountPage/SelectAccountPage';

import { AccountItem } from './AccountItem/AccountItem';

interface AccountSegmentProps {
  heading: string;
  subheading: string;
  accounts: AccountSelectionResults['edges'];
  handleActivateAccount?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    node: accountPartsFragment,
  ) => void;
  pendingAccountEmailStatuses?: PendingAccountEmailStatuses;
}

const AccountSegment = ({
  heading,
  subheading,
  accounts,
  handleActivateAccount,
  pendingAccountEmailStatuses,
}: AccountSegmentProps) => (
  <PageBlock width="medium">
    <Stack space="large">
      <Stack space="small">
        {heading ? (
          <Heading level="4" component="h3">
            {heading}
          </Heading>
        ) : null}
        {subheading ? <Text>{subheading}</Text> : null}
      </Stack>

      <Stack space="large">
        <Divider />
        {accounts.map(({ node }) => (
          <Fragment key={node.hirerAccountId}>
            {/* Parent */}
            <AccountItem
              option={node}
              handleActivateAccount={handleActivateAccount}
              pendingAccountEmailStatuses={pendingAccountEmailStatuses}
            />

            {/* Children */}
            {node.childAccounts?.map((childAccountOption) => (
              <AccountItem
                option={childAccountOption}
                key={childAccountOption.hirerAccountId}
              />
            ))}
            <Divider />
          </Fragment>
        ))}
      </Stack>
    </Stack>
  </PageBlock>
);

export default AccountSegment;
