import { useTranslations } from '@vocab/react';
import { Alert, Text } from 'braid-design-system';
import { useEffect, useRef, useState } from 'react';

import { trackPageInteraction } from 'src/modules/AnalyticsTracker';
import translations from 'src/views/shared/forms/RegistrationForm/.vocab';

interface Props {
  hasMismatch: boolean;
}

export const PhoneCountryMismatchAlert = ({ hasMismatch = false }: Props) => {
  const [hasCountryMismatch, setHasCountryMismatch] = useState(hasMismatch);
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { t } = useTranslations(translations);

  /* watches for country code mismatch changes,
    prevents visual flicker and tracking effect duplication */
  useEffect(() => {
    setHasCountryMismatch(hasMismatch);

    if (hasCountryMismatch) {
      clearTimeout(timeoutRef.current);
      const timeoutRefId = setTimeout(() => setIsVisible(true), 500);
      timeoutRef.current = timeoutRefId;
    } else if (!hasCountryMismatch) {
      clearTimeout(timeoutRef.current);
      setIsVisible(false);
    }
  }, [hasMismatch, hasCountryMismatch]);

  useEffect(() => {
    if (isVisible) {
      trackPageInteraction('alert_viewed', 'account registration', {
        fieldName: 'phone-number',
        alertShownMessage: t('Phone number mismatch warning'),
        alertShownMessageCode: t('Phone number mismatch warning'),
        alertShownPosition: 'content',
        alertShownTone: 'info',
        alertShownType: 'notice',
        currentPage: 'account registration',
      });
    }
  }, [isVisible, t]);

  const handleClose = () => {
    trackPageInteraction('alert_dismiss_pressed', 'account registration', {
      fieldName: 'phone-number',
      alertShownMessage: t('Phone number mismatch warning'),
      alertShownMessageCode: t('Phone number mismatch warning'),
      alertShownPosition: 'content',
      alertShownTone: 'info',
      alertShownType: 'notice',
      currentPage: 'account registration',
    });

    setIsVisible(false);
  };

  return isVisible ? (
    <Alert
      tone="info"
      onClose={handleClose}
      closeLabel="Dismiss alert"
      data={{ testid: 'mismatch-text' }}
    >
      <Text>{t('Phone number mismatch warning')}</Text>
    </Alert>
  ) : null;
};
