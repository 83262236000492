import { required, rules } from '@seek/validators-js';
import { useTranslations } from '@vocab/react';

import translations from 'src/views/shared/forms/RegistrationForm/.vocab';
import { useField } from 'src/views/shared/forms/RegistrationForm/RegistrationFormContext';
import {
  isLongPhone,
  isShortPhone,
  noStartingSpace,
} from 'src/views/shared/forms/utils/customValidationHelpers';

export default () => {
  const { t } = useTranslations(translations);

  const businessNameValidationRules = rules.hirerBusinessName({
    requiredLabel: t('Business name required'),
  });

  const businessName = useField({
    id: 'businessName',
    validators: [
      required,
      noStartingSpace,
      ...businessNameValidationRules.validators,
    ],
    validatorProps: {
      ...businessNameValidationRules.validatorProps,
      fieldLabel: t('Business name'),
    },
  });

  const phoneValidationRules = rules.hirerPhoneNumber();

  const phoneNumber = useField({
    id: 'phoneNumber',
    validators: [required, noStartingSpace, isShortPhone, isLongPhone] as any,
    validatorProps: {
      ...phoneValidationRules.validatorProps,
      requiredLabel: t('Phone number required'),
      fieldLabel: t('Phone number'),
    },
  });

  const isAgency = useField({
    id: 'isAgency',
    validators: [],
    validatorProps: {},
  });

  const phoneNumberCountryCode = useField({
    id: 'phoneNumberCountryCode',
    validators: [],
    validatorProps: {},
  });

  return {
    businessName,
    phoneNumber,
    isAgency,
    phoneNumberCountryCode,
  };
};
