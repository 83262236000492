
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50Ijoi4Liq4LmI4LiH4Lit4Li14LmA4Lih4Lil4LmB4Lil4LmJ4LinIiwiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiOiLguKrguYjguIfguK3guLXguYDguKHguKXguIHguLLguKPguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguK3guLXguIHguITguKPguLHguYnguIciLCJUcnkgYWdhaW4gbGF0ZXIiOiLguYLguJvguKPguJTguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50Ijoi4Liq4LmI4LiH4Lit4Li14LmA4Lih4Lil4LmB4Lil4LmJ4LinIiwiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiOiLguKrguYjguIfguK3guLXguYDguKHguKXguIHguLLguKPguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguK3guLXguIHguITguKPguLHguYnguIciLCJUcnkgYWdhaW4gbGF0ZXIiOiLguYLguJvguKPguJTguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIcifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgdGVya2lyaW0iLCJSZXNlbmQgYWN0aXZhdGlvbiBlbWFpbCI6IktpcmltIHVsYW5nIGVtYWlsIGFrdGl2YXNpIiwiVHJ5IGFnYWluIGxhdGVyIjoiQ29iYSBsYWdpIG5hbnRpIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgdGVya2lyaW0iLCJSZXNlbmQgYWN0aXZhdGlvbiBlbWFpbCI6IktpcmltIHVsYW5nIGVtYWlsIGFrdGl2YXNpIiwiVHJ5IGFnYWluIGxhdGVyIjoiQ29iYSBsYWdpIG5hbnRpIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50Ijoi4Liq4LmI4LiH4Lit4Li14LmA4Lih4Lil4LmB4Lil4LmJ4LinIiwiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiOiLguKrguYjguIfguK3guLXguYDguKHguKXguIHguLLguKPguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguK3guLXguIHguITguKPguLHguYnguIciLCJUcnkgYWdhaW4gbGF0ZXIiOiLguYLguJvguKPguJTguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50Ijoi4Liq4LmI4LiH4Lit4Li14LmA4Lih4Lil4LmB4Lil4LmJ4LinIiwiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiOiLguKrguYjguIfguK3guLXguYDguKHguKXguIHguLLguKPguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguK3guLXguIHguITguKPguLHguYnguIciLCJUcnkgYWdhaW4gbGF0ZXIiOiLguYLguJvguKPguJTguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIcifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgdGVya2lyaW0iLCJSZXNlbmQgYWN0aXZhdGlvbiBlbWFpbCI6IktpcmltIHVsYW5nIGVtYWlsIGFrdGl2YXNpIiwiVHJ5IGFnYWluIGxhdGVyIjoiQ29iYSBsYWdpIG5hbnRpIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgdGVya2lyaW0iLCJSZXNlbmQgYWN0aXZhdGlvbiBlbWFpbCI6IktpcmltIHVsYW5nIGVtYWlsIGFrdGl2YXNpIiwiVHJ5IGFnYWluIGxhdGVyIjoiQ29iYSBsYWdpIG5hbnRpIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiRW1haWwgc2VudCIsIlJlc2VuZCBhY3RpdmF0aW9uIGVtYWlsIjoiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiLCJUcnkgYWdhaW4gbGF0ZXIiOiJUcnkgYWdhaW4gbGF0ZXIifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiW8OLbcyCxIPEg8SDw6zDrMOsxpogxaHhur3hur3hur3guIHguLXguYnhua9dIiwiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiOiJbxZjhur3hur3FoeG6veG6veC4geC4teC5icaMIMSDxIPDp+G5r8Osw6zhub3Eg8SD4bmvw6zDrMO2w7bguIHguLXguYkg4bq94bq9bcyCxIPEg8Osw6zGml0iLCJUcnkgYWdhaW4gbGF0ZXIiOiJb4bmuxZnDvcO9IMSDxIPEo8SDxIPDrMOs4LiB4Li14LmJIMaaxIPEg+G5r+G6veG6vcWZXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBzZW50IjoiW8OLbcyCxIPEg8SDw6zDrMOsxpogxaHhur3hur3hur3guIHguLXguYnhua9dIiwiUmVzZW5kIGFjdGl2YXRpb24gZW1haWwiOiJbxZjhur3hur3FoeG6veG6veC4geC4teC5icaMIMSDxIPDp+G5r8Osw6zhub3Eg8SD4bmvw6zDrMO2w7bguIHguLXguYkg4bq94bq9bcyCxIPEg8Osw6zGml0iLCJUcnkgYWdhaW4gbGF0ZXIiOiJb4bmuxZnDvcO9IMSDxIPEo8SDxIPDrMOs4LiB4Li14LmJIMaaxIPEg+G5r+G6veG6vcWZXSJ9!"
        )
      )
      });
  
      export { translations as default };
    