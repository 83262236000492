import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type { RegistrationOptions } from 'src/modules/ApiClient/types';

export const query = gql`
  query GetRegistrationOptions {
    registrationOptions {
      allowAgencyRegistration
    }
  }
`;

export type GetRegistrationOptions = () => Promise<RegistrationOptions>;

const getRegistrationOptions: GetRegistrationOptions =
  async (): Promise<RegistrationOptions> => {
    const graphqlClient = getClient();
    const result = await graphqlClient.query<{
      registrationOptions: RegistrationOptions;
    }>({
      query,
    });

    const { registrationOptions } = result.data;
    return registrationOptions;
  };

export default getRegistrationOptions;
