import { useTranslations } from '@vocab/react';
import { Heading, Stack, Text } from 'braid-design-system';

import translations from 'src/views/pages/RegistrationPage/.vocab';
import { useRegistrationPageContext } from 'src/views/pages/RegistrationPage/RegistrationPageContext';

import RegistrationAlert from './RegistrationAlert';

const RegistrationHeader = () => {
  const context = useRegistrationPageContext();
  const { t } = useTranslations(translations);

  if (context.loading) {
    return;
  }

  const { numberOfAccounts, userExists } = context;

  const hasAnotherAccount = numberOfAccounts > 0;

  return (
    <Stack space="xlarge">
      <Stack space="medium">
        <Heading level="2">
          {hasAnotherAccount
            ? t('Create new employer account')
            : t('Your employer account')}
        </Heading>
        {!hasAnotherAccount && <Text>{t('Almost done')}</Text>}
      </Stack>
      {userExists && (
        <RegistrationAlert
          hasAnotherAccount={hasAnotherAccount}
          numberOfAccounts={numberOfAccounts}
        />
      )}
    </Stack>
  );
};

export default RegistrationHeader;
