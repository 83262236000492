export const IconCalendarSchedule = (props: any) => (
  <svg
    viewBox="0 0 131 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.061 41.242h83V91.08c0 5.506-4.471 9.977-9.977 9.977H32.039c-5.507 0-9.978-4.47-9.978-9.977V41.242Z"
      fill="#F8B1DC"
    />
    <path
      d="M31.755 22.86h63.55c5.385 0 9.756 4.37 9.756 9.755v8.627H22v-8.627c0-5.384 4.371-9.756 9.755-9.756Z"
      fill="#EE399E"
    />
    <path
      d="M39.156 16a4.063 4.063 0 0 0-4.062 4.063v9.372a4.063 4.063 0 0 0 8.125 0v-9.372A4.063 4.063 0 0 0 39.156 16ZM87.904 16a4.063 4.063 0 0 0-4.062 4.063v9.372a4.063 4.063 0 0 0 8.125 0v-9.372A4.063 4.063 0 0 0 87.904 16Z"
      fill="#0D3880"
    />
    <path
      d="M72.55 51.516h-4.403c-.948 0-1.716.768-1.716 1.716v4.404c0 .948.768 1.716 1.716 1.716h4.404c.948 0 1.716-.768 1.716-1.716v-4.404c0-.948-.768-1.716-1.716-1.716ZM58.914 65.156H54.51c-.948 0-1.716.769-1.716 1.716v4.404c0 .948.768 1.717 1.716 1.717h4.404c.948 0 1.716-.769 1.716-1.716v-4.404c0-.948-.768-1.717-1.716-1.717Z"
      fill="#EE399E"
    />
    <path
      d="M44.907 81.79ZM45.276 51.516h-4.404c-.947 0-1.716.768-1.716 1.716v4.404c0 .948.769 1.716 1.717 1.716h4.404c.947 0 1.716-.768 1.716-1.716v-4.404c0-.948-.769-1.716-1.717-1.716ZM58.914 51.516H54.51c-.948 0-1.716.768-1.716 1.716v4.404c0 .948.768 1.716 1.716 1.716h4.404c.948 0 1.716-.768 1.716-1.716v-4.404c0-.948-.768-1.716-1.716-1.716Z"
      fill="#fff"
    />
    <path
      d="M86.189 51.516h-4.404c-.948 0-1.717.768-1.717 1.716v4.404c0 .948.769 1.716 1.717 1.716h4.404c.948 0 1.716-.768 1.716-1.716v-4.404c0-.948-.769-1.716-1.716-1.716Z"
      fill="#EE399E"
    />
    <path
      d="M45.276 65.156h-4.404c-.947 0-1.716.769-1.716 1.716v4.404c0 .948.769 1.717 1.717 1.717h4.404c.947 0 1.716-.769 1.716-1.716v-4.404c0-.948-.769-1.717-1.717-1.717Z"
      fill="#fff"
    />
    <path
      d="M72.55 65.156h-4.403c-.948 0-1.716.769-1.716 1.716v4.404c0 .948.768 1.717 1.716 1.717h4.404c.948 0 1.716-.769 1.716-1.716v-4.404c0-.948-.768-1.717-1.716-1.717Z"
      fill="#EE399E"
    />
    <path
      d="M86.189 65.156h-4.404c-.948 0-1.717.769-1.717 1.716v4.404c0 .948.769 1.717 1.717 1.717h4.404c.948 0 1.716-.769 1.716-1.716v-4.404c0-.948-.769-1.717-1.716-1.717ZM45.276 78.797h-4.404c-.947 0-1.716.768-1.716 1.716v4.404c0 .948.769 1.716 1.717 1.716h4.404c.947 0 1.716-.768 1.716-1.716v-4.404c0-.948-.769-1.716-1.717-1.716ZM58.914 78.797H54.51c-.948 0-1.716.768-1.716 1.716v4.404c0 .948.768 1.716 1.716 1.716h4.404c.948 0 1.716-.768 1.716-1.716v-4.404c0-.948-.768-1.716-1.716-1.716Z"
      fill="#fff"
    />
    <path
      d="M72.55 78.797h-4.403c-.948 0-1.716.768-1.716 1.716v4.404c0 .948.768 1.716 1.716 1.716h4.404c.948 0 1.716-.768 1.716-1.716v-4.404c0-.948-.768-1.716-1.716-1.716Z"
      fill="#EE399E"
    />
    <path
      d="M86.189 78.797h-4.404c-.948 0-1.717.768-1.717 1.716v4.404c0 .948.769 1.716 1.717 1.716h4.404c.948 0 1.716-.768 1.716-1.716v-4.404c0-.948-.769-1.716-1.716-1.716Z"
      fill="#fff"
    />
    <path
      d="M112.535 74.483c-2.651-4.558-9.235-4.558-11.886 0l-16.03 27.56c-2.666 4.584.64 10.332 5.943 10.332h32.06c5.302 0 8.609-5.748 5.943-10.332l-16.03-27.56Z"
      fill="#FDC221"
      stroke="#fff"
      strokeWidth="2.75"
    />
    <path
      d="M106.656 102.655a1.824 1.824 0 1 0 0-3.649 1.824 1.824 0 0 0 0 3.649ZM106.656 95.347a1.824 1.824 0 0 0 1.824-1.824V86.23a1.823 1.823 0 1 0-3.647 0v7.293a1.823 1.823 0 0 0 1.823 1.824Z"
      fill="#2E3849"
    />
  </svg>
);
