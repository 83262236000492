import { useTranslations } from '@vocab/react';
import { useEffect, useState } from 'react';

import type { SecureLinkErrorType } from 'src/types';
import { getQueryParams } from 'src/utils/queryParams';
import { EmptyPageLayout } from 'src/views/layouts/EmptyPageLayout/EmptyPageLayout';
import { ContactDetails } from 'src/views/shared/ContactDetails/ContactDetails';
import { IconCalendarSchedule } from 'src/views/shared/icons/IconCalendarSchedule';
import { IconDesktopWarning } from 'src/views/shared/icons/IconDesktopWarning';
import { IconEmailVerified } from 'src/views/shared/icons/IconEmailVerified';

import translations from './.vocab';

const SecureLinkErrorPage = () => {
  const [errorType, setErrorType] = useState<SecureLinkErrorType | undefined>();

  const { t } = useTranslations(translations);

  useEffect(() => {
    const { reason } = getQueryParams();
    setErrorType((reason as SecureLinkErrorType) || 'Invalid');
  }, []);

  const getErrorImage = () => {
    switch (errorType) {
      case 'expired':
        return <IconCalendarSchedule />;
      case 'used':
        return <IconEmailVerified />;
      default:
        return <IconDesktopWarning />;
    }
  };

  const getErrorHeading = () => {
    switch (errorType) {
      case 'expired':
        return t('This link has expired');
      case 'used':
        return t('Your email address is verified');
      default:
        return t('This link isnt working');
    }
  };

  return (
    <EmptyPageLayout image={getErrorImage()} heading={getErrorHeading()}>
      {errorType !== 'used' ? <ContactDetails /> : null}
    </EmptyPageLayout>
  );
};

export default SecureLinkErrorPage;
