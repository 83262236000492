import graphQLClient from '@seek/hirer-graphql-client';
import type { ApolloClientWithCache } from '@seek/hirer-graphql-client/lib-types/client/createClient';

import {
  URL_HIRER_GRAPH,
  URL_HIRER_GRAPH_STAGING,
} from 'src/constants/apiEndpoints';
import { ENV_DEV, ENV_PRODUCTION } from 'src/constants/environments';
import getTokenData from 'src/modules/AuthHandler/v2/getTokenData';

export const getAuthToken = async (): Promise<string | null> => {
  try {
    const data = await getTokenData();
    if (data.authenticated) {
      return data.token;
    }

    return null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getting access token:', error);
    return null;
  }
};

const login = () => {};

const logMetrics = () => {};

const logGraphQLError = (
  graphQlError: any,
  operationName: any,
  operationMetadata: any,
) => {
  // eslint-disable-next-line no-console
  console.log('graphQlError: ', graphQlError);
  // eslint-disable-next-line no-console
  console.log('operationName: ', operationName);
  // eslint-disable-next-line no-console
  console.log('operationMetadata: ', operationMetadata);
};

const logError = (
  networkError: any,
  operationName: any,
  operationMetadata: any,
) => {
  // eslint-disable-next-line no-console
  console.log('networkError: ', networkError);
  // eslint-disable-next-line no-console
  console.log('operationName: ', operationName);
  // eslint-disable-next-line no-console
  console.log('operationMetadata: ', operationMetadata);
};

const getHeaders = (): Headers => new Headers();

const getGraphUri = () => {
  const environment =
    typeof window === 'undefined'
      ? ENV_PRODUCTION
      : window.ADV_SHOPFRONT_CONFIG.environment;

  return environment === ENV_DEV ? URL_HIRER_GRAPH_STAGING : URL_HIRER_GRAPH;
};

const getSite = () => {
  const site =
    typeof window !== 'undefined' && window.ADV_SHOPFRONT_CONFIG.site;
  return site;
};

let client: ApolloClientWithCache;

const getClient = () => {
  if (!client) {
    // GQL client is only available on front end
    client = graphQLClient({
      appName: 'shopfront',
      site: getSite(),
      // online-identity uses the same method to get and renew
      getAuthToken,
      renewAuthToken: getAuthToken,
      login,
      logMetrics,
      logGraphQLError,
      logError,
      // getHeaders only works client side (as Headers object doesn't exist on the server)
      getHeaders: typeof window === 'undefined' ? undefined : getHeaders,
      uri: getGraphUri(),
    });
  }

  return client;
};

export default getClient;
