import {
  URL_ACCOUNT_HIRER_REGISTRATION,
  URL_ACCOUNT_SELECT,
  URL_REGISTER_ADVERTISER_COMPLETE,
  URL_ACCOUNT_ADDITIONAL_ADVERTISER_REGISTRATION_COMPLETE,
  URL_ROOT,
} from '@seek/adv-constants';

import {
  URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION,
  URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION_ERROR,
  URL_COMPLETE_USER_REGISTRATION,
  URL_COMPLETE_USER_REGISTRATION_ERROR,
  URL_REGISTER_AGENCY_COMPLETE,
  URL_USER_VERIFICATION_COMPLETE,
} from './page-urls';

// app routes
export const ROUTE_ROOT = URL_ROOT;

export const ROUTE_ACCOUNT_REGISTRATION = URL_ACCOUNT_HIRER_REGISTRATION;

export const ROUTE_ACCOUNT_SELECT = URL_ACCOUNT_SELECT;

export const ROUTE_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION =
  URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION;

export const ROUTE_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION_ERROR =
  URL_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION_ERROR;

export const ROUTE_COMPLETE_USER_REGISTRATION = URL_COMPLETE_USER_REGISTRATION;

export const ROUTE_COMPLETE_USER_REGISTRATION_ERROR =
  URL_COMPLETE_USER_REGISTRATION_ERROR;

export const ROUTE_REGISTER_ADDITIONAL_ADVERTISER_COMPLETE =
  URL_ACCOUNT_ADDITIONAL_ADVERTISER_REGISTRATION_COMPLETE;

export const ROUTE_REGISTER_ADVERTISER_COMPLETE =
  URL_REGISTER_ADVERTISER_COMPLETE;

export const ROUTE_REGISTER_AGENCY_COMPLETE = URL_REGISTER_AGENCY_COMPLETE;

export const ROUTE_USER_VERIFICATION_COMPLETE = URL_USER_VERIFICATION_COMPLETE;
