import { createForm, type RenderParams } from '@seek/forms-ui';
import { VocabProvider } from '@vocab/react';
import { createContext, useContext } from 'react';

import { useConfig } from 'src/hooks/context';
import {
  countryNameToCode,
  siteNameToCountryName,
} from 'src/utils/siteNameToCountryName';

export interface FormValues {
  firstName: string;
  lastName: string;
  businessName: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  isAgency: boolean;
  country: string;
  countryCode: string;
  taxIdentificationNumber?: string;
  businessIdentifier?: string;
}

interface MappedProps {
  values: FormValues;
}

// SEEK forms-ui context.
const { FormProvider, useField } = createForm<FormValues, MappedProps>();
export { useField };

type RegistrationFormContextType = RenderParams<FormValues> & MappedProps;

/**
 * React Context that wraps the SEEK forms-ui context so we can use it in the RegistrationForm components without prop drilling.
 */
const RegistrationFormContext = createContext<
  RegistrationFormContextType | undefined
>(undefined);

export const RegistrationFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { site, locale } = useConfig();

  const countryName = siteNameToCountryName(site);
  const countryCode = countryNameToCode(countryName) || 'au';

  return (
    <VocabProvider language={locale}>
      <FormProvider
        mapFormStateToProps={(formState) => ({
          values: formState.values,
        })}
        initialValues={{
          firstName: '',
          lastName: '',
          businessName: '',
          phoneNumber: '',
          phoneNumberCountryCode: '',
          country: countryName,
          countryCode,
          isAgency: false,
          taxIdentificationNumber: undefined,
          businessIdentifier: undefined,
        }}
        language={locale}
      >
        {(props) => (
          <RegistrationFormContext.Provider value={props}>
            {children}
          </RegistrationFormContext.Provider>
        )}
      </FormProvider>
    </VocabProvider>
  );
};

export const useRegistrationForm = () => {
  const context = useContext(RegistrationFormContext);

  if (!context) {
    throw new Error(
      'useRegistrationForm must be used within a RegistrationFormProvider',
    );
  }

  return context;
};
