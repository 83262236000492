import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type { RegistrationStatus } from 'src/modules/ApiClient/types';

const query = gql`
  query CheckRegistrationStatus {
    registrationStatus(input: { filter: "includeDeactivated" }) {
      userExists
    }
  }
`;

export type CheckRegistrationStatus = () => Promise<RegistrationStatus>;

const checkRegistrationStatus: CheckRegistrationStatus = async () => {
  const graphqlClient = getClient();
  const result = await graphqlClient.query<{
    registrationStatus: RegistrationStatus;
  }>({
    query,
  });

  const { registrationStatus } = result.data;
  return registrationStatus;
};

export default checkRegistrationStatus;
