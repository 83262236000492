import { PageBlock, Stack } from 'braid-design-system';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';

import type { BasePageLayoutProps } from 'src/types';

interface Props extends BasePageLayoutProps {
  heading: ReactNode;
  bannerComponent?: ReactNode;
  width?: ComponentPropsWithoutRef<typeof PageBlock>['width'];
}

export const PageLayout = ({
  bannerComponent,
  children,
  heading,
  width = 'medium',
}: Props) => (
  <Stack space="xlarge">
    <PageBlock width={width}>{heading}</PageBlock>
    {bannerComponent ? (
      <PageBlock width={width}>{bannerComponent}</PageBlock>
    ) : null}
    {children}
  </Stack>
);
