import { type RumEvent, datadogRum } from '@datadog/browser-rum';

import type {
  ApplicationLogContext,
  ErrorContext,
  LogContext,
  Logger,
  LoggerConfig,
} from 'src/utils/logger/logger';

export const redactEmailParams = (property: string) =>
  property
    .replace(/email=[^&]*/, 'email=REDACTED')
    .replace(/email_hint=[^&]*/, 'email_hint=REDACTED');

const loadDatadogRUM = ({
  buildVersion,
  enabled,
  environment,
  country,
}: DatadogLoggerConfig) => {
  datadogRum.init({
    applicationId: '15882f44-560d-465b-9f5f-e6b0f9ff0b39',
    clientToken: 'pubf6b075142aca70bcd52655b2bc40cb53',
    site: 'datadoghq.com',
    service: 'shopfront-ui',
    version: `${buildVersion}-country-${country}`,
    env: environment,
    sessionSampleRate: enabled ? 100 : 0,
    sessionReplaySampleRate: 20,
    trackUserInteractions: false,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event: RumEvent): boolean => {
      event.view.url = redactEmailParams(event.view.url);
      return true;
    },
  });

  datadogRum.startSessionReplayRecording();
};

const configureDatadogGlobalTags = (
  context: Partial<ApplicationLogContext>,
) => {
  const tags = {
    ...datadogRum.getGlobalContext(),
    ...context,
  };
  datadogRum.setGlobalContext(tags);
};

interface DatadogLoggerConfig extends LoggerConfig {
  enabled?: boolean;
}

export const createDatadogLogger = ({
  buildVersion,
  enabled = true,
  environment,
  country,
}: DatadogLoggerConfig): Logger => {
  loadDatadogRUM({ environment, enabled, buildVersion, country });
  datadogRum.setGlobalContextProperty('sessionContext', {
    'user-country': country,
  });
  return Object.freeze({
    info: (message: string, context: LogContext | undefined) =>
      datadogRum.addAction(context?.activity ?? message, {
        ...context,
        message,
      }),
    error: (message: string, context: ErrorContext | undefined) => {
      const error = context?.error ?? new Error(message);
      datadogRum.addError(error, {
        ...context,
        message,
      });
    },
    configureApplicationContext: configureDatadogGlobalTags,
  });
};
