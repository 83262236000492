import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type {
  SecureLinkMutation,
  SecureLinkResponse,
} from 'src/modules/ApiClient/types';

export const mutation = gql`
  mutation CompleteUserRegistration($input: SecureLinkInput!) {
    completeUserRegistration(input: $input) {
      ... on SecureLinkSuccessResponse {
        __typename
        redirectUri
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;

const completeUserRegistration: SecureLinkMutation = async (input) => {
  const graphqlClient = getClient();

  const result = await graphqlClient.mutate<{
    completeUserRegistration: SecureLinkResponse;
  }>({
    variables: { input },
    mutation,
  });

  return result.data?.completeUserRegistration;
};

export default completeUserRegistration;
