import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type { RegisterAdditionalAdvertiserInput } from 'src/modules/ApiClient/types';

export const mutation = gql`
  mutation RegisterAdditionalAdvertiser(
    $input: RegisterAdditionalAdvertiserInput!
  ) {
    registerAdditionalAdvertiser(input: $input) {
      seekId
    }
  }
`;

export type RegisterAdditionalAdvertiser = (
  input: RegisterAdditionalAdvertiserInput,
) => Promise<string>;

const registerAdditionalAdvertiser: RegisterAdditionalAdvertiser = async (
  input,
) => {
  const graphqlClient = getClient();
  await graphqlClient.mutate({
    variables: { input },
    mutation,
  });
  return '';
};

export default registerAdditionalAdvertiser;
