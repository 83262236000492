import { URL_ACCOUNT_HIRER_REGISTRATION } from '@seek/adv-constants';
import { useTrackLink } from '@seek/cmsu-analytics';
import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonLink,
  Column,
  Columns,
  Heading,
  Stack,
  PageBlock,
  useToast,
} from 'braid-design-system';
import { type UIEvent, useState } from 'react';

import { analyticsEvents } from 'src/constants/analytics';
import {
  RESEND_ACTIVATION_EMAIL_PRESSED,
  SELECT_ACCOUNT_PAGE_VIEW,
} from 'src/constants/pageViewUniqueKeys';
import {
  useGetAccountSelectionQuery,
  useResendActivationEmailMutation,
  type accountPartsFragment,
  type AccountSelectionEdgeV3,
} from 'src/graphql/generated';
import { useConfig } from 'src/hooks/context';
import { useAuthenticatedViewTracking } from 'src/modules/AnalyticsProvider/useAuthenticatedViewTracking';
import { accountEdgeSort } from 'src/utils/accountSorter';
import { getQueryParams } from 'src/utils/queryParams';
import { PageLayout } from 'src/views/layouts/PageLayout/PageLayout';

import translations from './.vocab';
import AccountDataErrorBanner from './components/AccountSegment/AccountDataErrorBanner/AccountDataErrorBanner';
import AccountPendingBanner from './components/AccountSegment/AccountPendingBanner/AccountPendingBanner';
import AccountSegment from './components/AccountSegment/AccountSegment';
import { isActiveAccount, isPendingAccount } from './helpers';

export interface PendingAccountEmailStatus {
  success?: boolean;
}
export type PendingAccountEmailStatuses = Record<
  string,
  PendingAccountEmailStatus
>;

const SelectAccountPage = () => {
  const [pendingAccountEmailStatuses, setPendingAccountEmailStatuses] =
    useState<PendingAccountEmailStatuses>({});
  const { returnUrl: returnUri } = getQueryParams();
  const { urlResolver, locale } = useConfig();
  const [userInfo] = useAuthenticatedViewTracking({
    eventName: SELECT_ACCOUNT_PAGE_VIEW,
  });
  const { t } = useTranslations(translations);

  const showToast = useToast();

  const trackResendAccountActivationEmail = useTrackLink(
    RESEND_ACTIVATION_EMAIL_PRESSED,
    {
      siteSection: 'pending activation',
      entryPoint: 'account selection - pending activation',
      ...userInfo,
    },
  );

  const onClickLink = useTrackLink(SELECT_ACCOUNT_PAGE_VIEW, {
    eventName: analyticsEvents.TRACK_CREATE_NEW_ACCOUNT,
    entryPoint: 'account selection - create new account',
    ...userInfo,
  });

  const {
    loading,
    error: accountSelectionQueryError,
    data,
  } = useGetAccountSelectionQuery({
    variables: {
      input: {
        locale,
        returnUri,
      },
    },
  });

  const [resendActivationEmailMutation] = useResendActivationEmailMutation();

  if (
    accountSelectionQueryError ||
    data?.accountSelectionV3.__typename === 'ResponseError'
  ) {
    return (
      <PageBlock width="medium">
        <AccountDataErrorBanner />
      </PageBlock>
    );
  }

  if (loading || !data) {
    return null;
  }

  const unsortedActiveAccounts = data.accountSelectionV3.edges.filter(
    ({ node }) => isActiveAccount(node),
  );

  const activeAccounts = accountEdgeSort(unsortedActiveAccounts);

  const unsortedPendingAccounts = data.accountSelectionV3.edges.filter(
    ({ node }) => isPendingAccount(node),
  ) as AccountSelectionEdgeV3[];
  const defaultAccount = data.accountSelectionV3.defaultAccount;

  const pendingAccounts = accountEdgeSort(unsortedPendingAccounts);

  if (defaultAccount) {
    window.location.assign(defaultAccount.redirectUrl);
  }

  if (data.accountSelectionV3.totalCount === 0) {
    window.location.assign(
      urlResolver({ path: URL_ACCOUNT_HIRER_REGISTRATION }),
    );
  }

  const hasPendingAccounts = pendingAccounts.length > 0;
  const hasActiveAccounts = activeAccounts.length > 0;
  // render nothing until data has been fetched and number of accounts is not 1.
  // otherwise we will redirect the user directly to dashboard.
  // This logic aims to avoid content flickers.
  const renderContent =
    !defaultAccount && (hasPendingAccounts || hasActiveAccounts);

  if (!renderContent) {
    return null;
  }

  const handleActivateAccount = async (
    event: UIEvent,
    account: accountPartsFragment,
  ) => {
    event.preventDefault();

    const hirerAccountId = account.hirerAccountId;

    try {
      await resendActivationEmailMutation({
        variables: {
          input: {
            hirerAccountId,
          },
        },
      });

      setPendingAccountEmailStatuses({
        ...pendingAccountEmailStatuses,
        [hirerAccountId]: {
          success: true,
        },
      });

      showToast({
        key: hirerAccountId,
        tone: 'positive',
        message: t('Activate account email sent'),
      });

      if (trackResendAccountActivationEmail) {
        trackResendAccountActivationEmail();
      }
    } catch {
      setPendingAccountEmailStatuses({
        ...pendingAccountEmailStatuses,
        [hirerAccountId]: {
          success: false,
        },
      });

      showToast({
        key: hirerAccountId,
        tone: 'critical',
        message: t('Could not send email'),
      });
    }
  };

  return (
    <PageLayout
      bannerComponent={
        <AccountPendingBanner
          pendingAccountsLength={pendingAccounts?.length || 0}
          userInfo={userInfo}
        />
      }
      heading={
        <Columns space="medium" collapseBelow="tablet" alignY="center">
          <Column>
            <Heading level="2">{t('Select an account')}</Heading>
          </Column>
          <Column width="content">
            <Box display="inlineBlock">
              <ButtonLink
                href={urlResolver({ path: URL_ACCOUNT_HIRER_REGISTRATION })}
                onClick={() => (onClickLink ? onClickLink() : undefined)}
                tone="formAccent"
              >
                {t('Create new account')}
              </ButtonLink>
            </Box>
          </Column>
        </Columns>
      }
    >
      <Stack space="xlarge">
        {hasActiveAccounts && (
          <AccountSegment
            heading={t('Active accounts')}
            subheading={t('Select an account to post')}
            accounts={activeAccounts}
          />
        )}
        {hasPendingAccounts && (
          <Box id="pending-accounts">
            <AccountSegment
              heading={t('Pending accounts')}
              subheading={t('Activate these accounts')}
              accounts={pendingAccounts}
              handleActivateAccount={handleActivateAccount}
              pendingAccountEmailStatuses={pendingAccountEmailStatuses}
            />
          </Box>
        )}
      </Stack>
    </PageLayout>
  );
};

export default SelectAccountPage;
