import { PageBlock } from 'braid-design-system';

import { PageLayout } from 'src/views/layouts/PageLayout/PageLayout';
import RegistrationForm from 'src/views/shared/forms/RegistrationForm/RegistrationForm';

import RegistrationHeader from './RegistrationHeader';
import { RegistrationPageProvider } from './RegistrationPageContext';

const RegistrationPage = () => {
  const pageWidth = 'small';

  return (
    <RegistrationPageProvider>
      <PageLayout heading={<RegistrationHeader />} width={pageWidth}>
        <PageBlock width={pageWidth}>
          <RegistrationForm />
        </PageBlock>
      </PageLayout>
    </RegistrationPageProvider>
  );
};

export default RegistrationPage;
