/**
 * Shallowly flips the keys and values of an object.
 */
export const flip = <
  X extends string | number | symbol,
  Y extends string | number | symbol,
>(
  data: Record<X, Y>,
): Record<Y, X> =>
  Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));
