
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiLguJXguLTguJTguJXguYjguK3guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYwiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6IuC4leC4tOC4lOC4leC5iOC4reC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jOC4l+C4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiLguJXguLTguJTguJXguYjguK3guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYwiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6IuC4leC4tOC4lOC4leC5iOC4reC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jOC4l+C4teC5iCJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJIdWJ1bmdpIExheWFuYW4gUGVsYW5nZ2FuIiwicmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gb24iOiJodWJ1bmdpIHRpbSBMYXlhbmFuIFBlbGFuZ2dhbiBrYW1pIGRpIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJIdWJ1bmdpIExheWFuYW4gUGVsYW5nZ2FuIiwicmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gb24iOiJodWJ1bmdpIHRpbSBMYXlhbmFuIFBlbGFuZ2dhbiBrYW1pIGRpIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiLguJXguLTguJTguJXguYjguK3guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYwiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6IuC4leC4tOC4lOC4leC5iOC4reC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jOC4l+C4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiLguJXguLTguJTguJXguYjguK3guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYwiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6IuC4leC4tOC4lOC4leC5iOC4reC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jOC4l+C4teC5iCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJIdWJ1bmdpIExheWFuYW4gUGVsYW5nZ2FuIiwicmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gb24iOiJodWJ1bmdpIHRpbSBMYXlhbmFuIFBlbGFuZ2dhbiBrYW1pIGRpIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJIdWJ1bmdpIExheWFuYW4gUGVsYW5nZ2FuIiwicmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gb24iOiJodWJ1bmdpIHRpbSBMYXlhbmFuIFBlbGFuZ2dhbiBrYW1pIGRpIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiLCJyZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBvbiI6InJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJbw4fDtsO2w7bguIHguLXguYnhua/Eg8SDxIPDp+G5ryDDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9XSIsInJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIjoiW8WZ4bq94bq94bq9xIPEg8SDw6fhuKkgw7bDtsO2x5rHmsea4bmvIOG5r8O2w7bDtiDDtsO2w7bHmseax5rFmSDDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23MgiDDtsO2w7bguIHguLXguYldIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb250YWN0IEN1c3RvbWVyIFNlcnZpY2UiOiJbw4fDtsO2w7bguIHguLXguYnhua/Eg8SDxIPDp+G5ryDDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9XSIsInJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIG9uIjoiW8WZ4bq94bq94bq9xIPEg8SDw6fhuKkgw7bDtsO2x5rHmsea4bmvIOG5r8O2w7bDtiDDtsO2w7bHmseax5rFmSDDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23MgiDDtsO2w7bguIHguLXguYldIn0=!"
        )
      )
      });
  
      export { translations as default };
    