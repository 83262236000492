import type { SecureLinkFlow } from 'src/types';
import PageLoader from 'src/views/shared/PageLoader/PageLoader';

import useSecureLink from './useSecureLink';

interface Props {
  flow: SecureLinkFlow;
}

const SecureLinkHandlerPage = ({ flow }: Props) => {
  useSecureLink(flow);

  return <PageLoader />;
};

export default SecureLinkHandlerPage;
