import { useEffect } from 'react';

import getContext from 'src/modules/ApiClient/hirerGraph/queries/gql_getContext';
import getHeaderFooterContext from 'src/modules/ApiClient/hirerGraph/queries/gql_getHeaderFooterContext';

import { trackPageLoad } from '.';

interface TrackingProps {
  page?: string;
  currentPage?: string;
  eventName?: string;
  siteSection: string;
  subSection?: string;
  userId?: string;
  seekAdvertiserId?: string;
  advertiserAdCentredId?: string;
  siteLanguage?: string;
  siteCountry?: string;
  brand?: string;
  zone?: string;
}

export default (
  input: Omit<TrackingProps, 'loginStatus' | 'userId' | 'seekAdvertiserId'>,
  suppressContextChecks = false,
) => {
  const performAsyncTracking = async () => {
    const page = input.currentPage ? input.currentPage : input.page;
    if (!page) {
      throw new Error('page or currentPage are mandatory values');
    }

    if (suppressContextChecks) {
      trackPageLoad(page, {
        ...input,
      });

      return;
    }
    try {
      const { currentAdvertiser, currentUser } = await getContext();
      const { user, identityContext } = await getHeaderFooterContext();

      const props: TrackingProps = {
        ...input,
        ...(!currentUser
          ? {
              isLoggedIn: false,
            }
          : {
              isLoggedIn: true,
              userId: currentUser.id,
              ...(currentAdvertiser
                ? {
                    seekAdvertiserId: currentAdvertiser.seekId,
                    advertiserAdCentreId: currentAdvertiser.advertiserId,
                  }
                : {}),
              ...(identityContext
                ? { hirerUserEmail: identityContext.email }
                : {}),
              ...(user ? { hirerUserFullName: user.name } : {}),
            }),
      };

      if (props.currentPage) {
        delete props.page;
      }

      trackPageLoad(page, props);
    } catch {
      trackPageLoad(page, {
        ...input,
        contextError: true,
      });
    }
  };

  useEffect(() => {
    performAsyncTracking();
  });
};
