import { URL_PRODUCTS_HUB } from '@seek/adv-constants';
import { siteNames } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonIcon,
  Column,
  Columns,
  IconClear,
  TextLink,
  Text,
  PageBlock,
} from 'braid-design-system';
import { useState } from 'react';

import { useConfig } from 'src/hooks/context';
import { trackPageInteraction } from 'src/modules/AnalyticsTracker';

import translations from './.vocab';

const actionOrigin = 'shopfront - hero banner';

export const PromotionalBanner = () => {
  const { isAnz, urlResolver, site } = useConfig();
  const { t } = useTranslations(translations);
  const [isVisible, setIsVisible] = useState(true);

  if (isAnz || !isVisible) {
    return null;
  }

  const onClose = () => {
    trackPageInteraction('content_element_pressed', actionOrigin, {
      elementDesign: 'button',
      elementText: 'X',
      elementAction: 'close banner',
      entryPoint: actionOrigin,
      currentPage: 'shopfront',
    });
    setIsVisible(false);
  };

  const handleLinkClick = () => {
    trackPageInteraction('content_element_pressed', actionOrigin, {
      elementDesign: 'link',
      elementText: `find out what's new`,
      elementAction: 'internal redirect',
      entryPoint: actionOrigin,
      currentPage: 'shopfront',
    });
  };

  const getAsiaBrand = () => {
    switch (site) {
      case siteNames.employer.seek.hk:
      case siteNames.employer.seek.th:
        return 'Jobsdb';
      default:
        return 'Jobstreet';
    }
  };

  return (
    <Box
      id="promo-banner"
      background="brandAccent"
      paddingY="medium"
      position="relative"
      overflow="hidden"
      role="alert"
    >
      <PageBlock width="medium">
        <Columns space="small" alignY="center">
          <Column>
            <Text>
              {t('Welcome', { brand: getAsiaBrand() })}{' '}
              <TextLink
                href={urlResolver({ path: URL_PRODUCTS_HUB })}
                onClick={handleLinkClick}
              >
                {t('Find out more')}
              </TextLink>
            </Text>
          </Column>
          <Column width="content">
            <ButtonIcon
              icon={<IconClear />}
              label={t('Close')}
              id="promo-banner-close-btn"
              onClick={onClose}
              size="small"
            />
          </Column>
        </Columns>
      </PageBlock>
    </Box>
  );
};
