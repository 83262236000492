import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type {
  SecureLinkMutation,
  SecureLinkResponse,
} from 'src/modules/ApiClient/types';

export const mutation = gql`
  mutation CompleteAdditionalAdvertiserRegistration($input: SecureLinkInput!) {
    completeAdditionalAdvertiserRegistration(input: $input) {
      ... on SecureLinkSuccessResponse {
        __typename
        redirectUri
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;

const completeAdditionalAdvertiserRegistration: SecureLinkMutation = async (
  input,
) => {
  const graphqlClient = getClient();
  const result = await graphqlClient.mutate<{
    completeAdditionalAdvertiserRegistration: SecureLinkResponse;
  }>({
    variables: { input },
    mutation,
  });

  return result.data?.completeAdditionalAdvertiserRegistration;
};

export default completeAdditionalAdvertiserRegistration;
