import { useTrackLink } from '@seek/cmsu-analytics';
import { required, rules } from '@seek/validators-js';
import { useTranslations } from '@vocab/react';
import {
  Actions,
  Button,
  FieldLabel,
  Stack,
  Columns,
  Column,
  TextField,
} from 'braid-design-system';
import { type SyntheticEvent, useState } from 'react';

import {
  REGISTER_ACCOUNT_FIRST_FOCUS_FIELDS,
  REGISTER_ACCOUNT_FORM_VALIDATION_ERROR,
  REGISTER_ACCOUNT_SUBMIT,
} from 'src/constants/pageViewUniqueKeys';
import { shopfrontAnalyticsEvents } from 'src/views/pages/HomePage/analytics';
import { isValidSsoEmail } from 'src/views/shared/forms/utils/customValidationHelpers';

import translations from './.vocab';
import { useField } from './EmailCaptureFormContainer';

const TRACKING_FORM_NAME = 'email-capture-form';

export interface Props {
  onSubmit: any;
  isProcessingForm: boolean;
}

function EmailCaptureForm({ onSubmit, isProcessingForm }: Props) {
  const { t } = useTranslations(translations);
  const [state, setState] = useState({
    fieldInteracted: false,
  });

  const { fieldInteracted } = state;
  const emailValidationRules = rules.hirerEmail({
    requiredLabel: t('Register form required error label'),
  });

  const { value, onChange, onBlur, errorMessage } = useField({
    id: 'email',
    validators: [required, isValidSsoEmail, ...emailValidationRules.validators],
    validatorProps: emailValidationRules.validatorProps,
  });

  const trackFormSubmit = useTrackLink(
    REGISTER_ACCOUNT_SUBMIT,
    shopfrontAnalyticsEvents.registration_button_pressed,
  );

  const trackFormFirstInteraction = useTrackLink(
    REGISTER_ACCOUNT_FIRST_FOCUS_FIELDS,
    shopfrontAnalyticsEvents.form_first_interaction_pressed,
  );

  const trackFormError = useTrackLink(REGISTER_ACCOUNT_FORM_VALIDATION_ERROR, {
    ...shopfrontAnalyticsEvents.form_validation_error_displayed,
    errorMessage,
    fieldName: 'emailAddress',
  });

  const handleFieldFocus = () => {
    if (fieldInteracted) {
      return;
    }
    if (trackFormFirstInteraction) trackFormFirstInteraction();
    setState({ ...state, fieldInteracted: true });
  };

  const handleSubmit = (e: SyntheticEvent) => {
    if (trackFormSubmit) {
      trackFormSubmit();
    }
    if (trackFormError && errorMessage) {
      trackFormError();
    }
    onSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit} data-test={TRACKING_FORM_NAME}>
      <Stack space="xsmall" align="left">
        <FieldLabel label={t('Register form label')} htmlFor="email" />
        <Columns collapseBelow="tablet" space="xsmall">
          <Column>
            <TextField
              id="email"
              value={value}
              placeholder="example@domain.com"
              data={{ 'tracking-id': 'email' }}
              message={errorMessage}
              onFocus={handleFieldFocus}
              onChange={onChange}
              onBlur={onBlur}
              tone={errorMessage ? 'critical' : undefined}
              reserveMessageSpace={false}
              aria-label="email"
            />
          </Column>
          <Column width="content">
            <Actions>
              <Button
                type="submit"
                tone="brandAccent"
                loading={isProcessingForm}
              >
                {t('Register form button')}
              </Button>
            </Actions>
          </Column>
        </Columns>
      </Stack>
    </form>
  );
}

export default EmailCaptureForm;
