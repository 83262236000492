import { metrics } from '@seek/metrics-js';

import { METRIC_LABELS } from 'src/constants/metrics';

import toTags from './utils/toTags';

export function reportPagePerformance(path: string) {
  metrics.sendPagePerformance(toTags({ path }));
}

export function reportPageEntry(path: string) {
  metrics.count(METRIC_LABELS.PAGE_ENTRY, toTags({ path }));
}

export function reportPageLoad(pageName: string) {
  metrics.count(METRIC_LABELS.PAGE_LOAD, toTags({ pageName }));
}

export function reportInfo(info: any) {
  metrics.count(METRIC_LABELS.INFO, toTags({ info }));
  metrics.count(`${METRIC_LABELS.INFO}.${info}`);
}

export function reportWarning(warning: any) {
  metrics.count(METRIC_LABELS.WARNING, toTags({ warning }));
  metrics.count(`${METRIC_LABELS.WARNING}.${warning}`);
}

export function reportError(error: any, code = 'na') {
  metrics.count(METRIC_LABELS.ERROR, toTags({ error, code }));
  metrics.count(`${METRIC_LABELS.ERROR}.${error}`, toTags({ code }));
}

export function reportInteraction(interaction: string) {
  metrics.count(METRIC_LABELS.INTERACTION, toTags({ interaction }));
}
