import { Box, Loader, PageBlock } from 'braid-design-system';

const PageLoader = () => (
  <PageBlock>
    <Box
      paddingY="xlarge"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Loader size="large" />
    </Box>
  </PageBlock>
);

export default PageLoader;
