import { EMPLOYER_CONTACT_US_URL } from '@seek/shared-constants';
import { useTranslations } from '@vocab/react';
import { TextLink, Text, Stack } from 'braid-design-system';

import { useConfig } from 'src/hooks/context';
import { CsPhoneNumber } from 'src/views/shared/CsPhoneNumber/CsPhoneNumber';

import translations from './.vocab';

interface Props {
  component?: 'p' | 'span';
}

export const ContactDetails = ({ component = 'p' }: Props) => {
  const { isAnz, site, urlResolver } = useConfig();
  const { t } = useTranslations(translations);

  const AnzContactDetails = () => (
    <>
      {t('ANZ Contact customer service message')} <CsPhoneNumber site={site} />
    </>
  );

  const NonAnzContactDetails = () => (
    <>
      {t('Contact customer service message', {
        Link: (children) => (
          <TextLink
            href={urlResolver({
              path: EMPLOYER_CONTACT_US_URL[site],
            })}
            target="_blank"
          >
            {children}
          </TextLink>
        ),
      })}
    </>
  );

  return (
    <Stack space="small">
      <Text component={component}>{t('Need help')}</Text>
      <Text component={component}>
        {isAnz ? <AnzContactDetails /> : <NonAnzContactDetails />}
      </Text>
    </Stack>
  );
};
