import {
  AccessRightStatus,
  AdvertiserStatus,
  Role,
  type accountPartsFragment,
} from 'src/graphql/generated';

export const isActiveAccount = (account: accountPartsFragment) =>
  account.hirerAccountStatus === AdvertiserStatus.ACTIVE &&
  account.accessRightStatus === AccessRightStatus.ACTIVE;

export const isPendingAccount = (account: accountPartsFragment) =>
  // PENDING_ACTIVATION is an AdCentre value, PENDING is a accounts-db value.
  // Supporting both while we transition the account selection page off of p13n-api.
  (account.hirerAccountStatus === AdvertiserStatus.PENDING ||
    account.hirerAccountStatus === AdvertiserStatus.PENDING_ACTIVATION) &&
  account.role === Role.ADMINISTRATOR;
