import type { accountPartsFragment } from 'src/graphql/generated';
import type { AccountSelectionResults } from 'src/types';

const deepClone = <T>(array: T[]) =>
  JSON.parse(JSON.stringify(array)) as typeof array;

const getDisplayText = (account: {
  name: string;
  billingId?: string | null;
}): string => {
  if (account.billingId) {
    return `${account.name} ${account.billingId.replace(/^0+/, '')}`;
  }

  return account.name;
};

function accountOptionSort(accounts: accountPartsFragment[]) {
  accounts.sort((a, b) => (getDisplayText(a) > getDisplayText(b) ? 1 : -1));
}

export const accountEdgeSort = (
  accounts: AccountSelectionResults['edges'],
): AccountSelectionResults['edges'] => {
  const accountsClone = deepClone(accounts);
  accountsClone.sort((a, b) =>
    getDisplayText(a.node) > getDisplayText(b.node) ? 1 : -1,
  );

  accountsClone.forEach((account) => {
    if (account.node.childAccounts.length > 0) {
      accountOptionSort(account.node.childAccounts);
    }
  });

  return accountsClone;
};
