import {
  EmployerHeader,
  EmployerFooter,
  type ActiveTabId,
  type ActiveSubTabId,
} from '@seek/adv-header-footer';
import { Box, Page, Stack } from 'braid-design-system';
import { type ReactNode, useEffect, useState } from 'react';

import type { HeaderFooterContext } from 'src/graphql/generated';
import { useConfig } from 'src/hooks/context';
import { trackHeaderFooterInteractions } from 'src/modules/AnalyticsTracker';
import getHeaderFooterContext from 'src/modules/ApiClient/hirerGraph/queries/gql_getHeaderFooterContext';
import type { AuthenticationStatus } from 'src/types';

export interface Props {
  activeTabId?: ActiveTabId;
  activeSubTabId?: ActiveSubTabId;
  renderBasicHeader?: boolean;
  authenticationStatus: AuthenticationStatus;
  children: ReactNode;
  analyticsCurrentPage: string;
}

const MainLayout = ({
  activeTabId,
  activeSubTabId,
  renderBasicHeader = false,
  authenticationStatus = 'pending',
  children,
  analyticsCurrentPage,
}: Props) => {
  const { site, locale, isStaging } = useConfig();

  const [headerFooterContext, setHeaderFooterContext] = useState<
    HeaderFooterContext | undefined
  >();

  useEffect(() => {
    let isMounted = true;

    if (authenticationStatus !== 'unauthenticated') {
      const { pathname, search } = window.location;
      getHeaderFooterContext({
        location: { pathname, search },
      }).then((context) => {
        if (isMounted) {
          // suppress advertiser-specific header content while account switching and registering
          const { advertiser, ...rest } = context;
          setHeaderFooterContext(rest);
        }
      });
    } else {
      setHeaderFooterContext({ identityContext: null });
    }

    return () => {
      isMounted = false;
    };
  }, [authenticationStatus]);

  return (
    <Page
      footer={
        <EmployerFooter
          site={site}
          locale={locale}
          isStaging={isStaging}
          onTrackedItemInteraction={trackHeaderFooterInteractions(
            analyticsCurrentPage,
          )}
          advertiser={headerFooterContext?.advertiser}
          identityContext={headerFooterContext?.identityContext}
        />
      }
    >
      <Stack space={activeTabId === 'home' ? 'none' : 'xlarge'}>
        <EmployerHeader
          activeTabId={activeTabId}
          activeSubTabId={activeSubTabId}
          site={site}
          locale={locale}
          isStaging={isStaging}
          onTrackedItemInteraction={trackHeaderFooterInteractions(
            analyticsCurrentPage,
          )}
          basic={renderBasicHeader}
          advertiser={headerFooterContext?.advertiser}
          identityContext={headerFooterContext?.identityContext}
          user={headerFooterContext?.user}
          divider
        />
        <Box component="main">{children}</Box>
      </Stack>
    </Page>
  );
};

export default MainLayout;
