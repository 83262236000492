import { type ReactNode, useEffect, useState } from 'react';
import loadable from 'sku/@loadable/component';

import { useConfig } from 'src/hooks/context';
import { featureConfigs } from 'src/modules/FeatureToggler/config';
import { isOverridesAllowed } from 'src/modules/FeatureToggler/controller/utils';
import type { Feature } from 'src/modules/FeatureToggler/types';
import {
  getLocalStorageFeatureState,
  setLocalStorageFeatureState,
} from 'src/modules/FeatureToggler/utils/localStorageFeatureState';
import { isServerRendering } from 'src/utils/environment';

import { FeatureToggleContext } from './FeatureToggleContext';

const FeatureToggleController = loadable(() =>
  import('src/modules/FeatureToggler/controller/FeatureToggleController').then(
    (module) => ({
      default: module.FeatureToggleController,
    }),
  ),
);

interface ProviderProps {
  children: ReactNode;
}

export const FeatureToggleProvider = ({ children }: ProviderProps) => {
  const { environment, site } = useConfig();
  const input = { environment, site };

  // Feature flags can be turned on/off in local storage (for dev/testing)
  const [overrides, setOverrides] = useState<Feature[]>(
    getLocalStorageFeatureState(),
  );
  // Listen to changes to update local storage with the latest value
  useEffect(() => setLocalStorageFeatureState(overrides), [overrides]);

  // Calculate the value of the features based on configuration
  const features: Feature[] = featureConfigs.map((config) =>
    config.toFeature(input),
  );

  return (
    <FeatureToggleContext.Provider
      value={{
        features,
        overrides: isOverridesAllowed(environment) ? overrides : [],
        setOverrides,
      }}
    >
      {children}
      {!isServerRendering() && isOverridesAllowed(environment) && (
        <FeatureToggleController />
      )}
    </FeatureToggleContext.Provider>
  );
};
