import { URL_ACCOUNT_HIRER_REGISTRATION } from '@seek/adv-constants';
import { useTranslations } from '@vocab/react';
import { Alert, Text, TextLink } from 'braid-design-system';

import { useConfig } from 'src/hooks/context';

import translations from './.vocab';

const AccountDataErrorBanner = () => {
  const { urlResolver } = useConfig();
  const { t } = useTranslations(translations);

  return (
    <Alert tone="critical">
      <Text>
        {t('Could not retrieve accounts', {
          Link: (children) => (
            <TextLink
              href={urlResolver({
                path: URL_ACCOUNT_HIRER_REGISTRATION,
              })}
            >
              {children}
            </TextLink>
          ),
        })}
      </Text>
    </Alert>
  );
};

export default AccountDataErrorBanner;
