import { URL_ROOT } from '@seek/adv-constants';
import { ManagedContentBanner } from '@seek/cms-ui';
import { useTrackView } from '@seek/cmsu-analytics';
import { PageComponent } from '@seek/cmsu-components';
import type { Country } from '@seek/melways-sites';
import { Text, Stack, Alert, PageBlock } from 'braid-design-system';
import { useEffect } from 'react';

import { HOME_PAGE_VIEW } from 'src/constants/pageViewUniqueKeys';
import { useConfig } from 'src/hooks/context';
import type { CmsPageContent } from 'src/types';
import {
  blockItemRenderers,
  customBannerRenderers,
} from 'src/views/pages/pageRenderers';
import SeoMeta from 'src/views/shared/SeoMeta/SeoMeta';

import './HomePage.css';
import { PromotionalBanner } from './components/PromotionalBanner/PromotionalBanner';

interface HomePageProps {
  content?: CmsPageContent;
}

const HomePage = ({ content }: HomePageProps) => {
  const {
    absoluteEmployerUrlResolver,
    country,
    environment,
    isAnz,
    language,
    locale,
  } = useConfig();
  const trackFunction = useTrackView(HOME_PAGE_VIEW);

  useEffect(() => {
    if (trackFunction) {
      trackFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TEMP: Hide CMS testimonials for non ANZ sites
  // This can be removed once testimonials become available across all APAC sites in CMS
  useEffect(() => {
    const element = document.getElementById('testimonials');
    if (element) {
      if (!isAnz) {
        element.style.display = 'none';
      } else {
        element.style.display = '';
      }
    }
  }, [isAnz]);

  const fullURL = absoluteEmployerUrlResolver(URL_ROOT);

  return (
    <>
      <ManagedContentBanner
        platform="employer"
        context="hirer_shopfront"
        environment={environment}
        locale={locale}
      />

      {content ? (
        <>
          <SeoMeta
            title={content.seo?.title ?? 'SEEK Employer'}
            description={content.seo?.description ?? ''}
            url={fullURL}
          />
          <PromotionalBanner />
          <PageComponent
            language={language}
            melwaysLocale={locale}
            country={country.toLowerCase() as Country}
            fullURL={fullURL}
            page={content}
            wptSalesForceApi=""
            subscriptionFormPrivacyUrl=""
            subscriptionFormSourceName=""
            renderers={blockItemRenderers}
            bannerRenderers={customBannerRenderers}
          />
        </>
      ) : (
        // This _should_ never happen but in case it does, let the user know
        <Stack space="xlarge">
          <PageBlock>
            <Alert tone="critical">
              <Stack space="small">
                <Text weight="strong">Error loading page content</Text>
                <Text>
                  We were unable to fetch the content for this page. Please try
                  again or check back later.
                </Text>
              </Stack>
            </Alert>
          </PageBlock>
        </Stack>
      )}
    </>
  );
};

export default HomePage;
