
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IuC4peC4h+C4l+C4sOC5gOC4muC4teC4ouC4mSIsIlJlZ2lzdGVyIGZvcm0gbGFiZWwiOiLguKPguLDguJrguLjguK3guLXguYDguKHguKXguILguK3guIfguITguLjguJMiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoi4Lit4Li14LmA4Lih4LilIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IuC4peC4h+C4l+C4sOC5gOC4muC4teC4ouC4mSIsIlJlZ2lzdGVyIGZvcm0gbGFiZWwiOiLguKPguLDguJrguLjguK3guLXguYDguKHguKXguILguK3guIfguITguLjguJMiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoi4Lit4Li14LmA4Lih4LilIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IkRhZnRhciIsIlJlZ2lzdGVyIGZvcm0gbGFiZWwiOiJNYXN1a2thbiBlbWFpbCIsIlJlZ2lzdGVyIGZvcm0gcmVxdWlyZWQgZXJyb3IgbGFiZWwiOiJhbGFtYXQgZW1haWwifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IkRhZnRhciIsIlJlZ2lzdGVyIGZvcm0gbGFiZWwiOiJNYXN1a2thbiBlbWFpbCIsIlJlZ2lzdGVyIGZvcm0gcmVxdWlyZWQgZXJyb3IgbGFiZWwiOiJhbGFtYXQgZW1haWwifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IuC4peC4h+C4l+C4sOC5gOC4muC4teC4ouC4mSIsIlJlZ2lzdGVyIGZvcm0gbGFiZWwiOiLguKPguLDguJrguLjguK3guLXguYDguKHguKXguILguK3guIfguITguLjguJMiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoi4Lit4Li14LmA4Lih4LilIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IuC4peC4h+C4l+C4sOC5gOC4muC4teC4ouC4mSIsIlJlZ2lzdGVyIGZvcm0gbGFiZWwiOiLguKPguLDguJrguLjguK3guLXguYDguKHguKXguILguK3guIfguITguLjguJMiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoi4Lit4Li14LmA4Lih4LilIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IkRhZnRhciIsIlJlZ2lzdGVyIGZvcm0gbGFiZWwiOiJNYXN1a2thbiBlbWFpbCIsIlJlZ2lzdGVyIGZvcm0gcmVxdWlyZWQgZXJyb3IgbGFiZWwiOiJhbGFtYXQgZW1haWwifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IkRhZnRhciIsIlJlZ2lzdGVyIGZvcm0gbGFiZWwiOiJNYXN1a2thbiBlbWFpbCIsIlJlZ2lzdGVyIGZvcm0gcmVxdWlyZWQgZXJyb3IgbGFiZWwiOiJhbGFtYXQgZW1haWwifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlJlZ2lzdGVyIiwiUmVnaXN0ZXIgZm9ybSBsYWJlbCI6IkVudGVyIHlvdXIgZW1haWwiLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiYW4gZW1haWwgYWRkcmVzcyJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlvFmOG6veG6veG6vcSjw6zDrMOsxaHhua/hur3hur3hur3FmV0iLCJSZWdpc3RlciBmb3JtIGxhYmVsIjoiW8OL4LiB4Li14LmJ4bmv4bq94bq9xZkgw73DvcO2w7bHmseaxZkg4bq94bq9bcyCxIPEg8Osw6zGml0iLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiW8SDxIPEg+C4geC4teC5iSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBmb3JtIGJ1dHRvbiI6IlvFmOG6veG6veG6vcSjw6zDrMOsxaHhua/hur3hur3hur3FmV0iLCJSZWdpc3RlciBmb3JtIGxhYmVsIjoiW8OL4LiB4Li14LmJ4bmv4bq94bq9xZkgw73DvcO2w7bHmseaxZkg4bq94bq9bcyCxIPEg8Osw6zGml0iLCJSZWdpc3RlciBmb3JtIHJlcXVpcmVkIGVycm9yIGxhYmVsIjoiW8SDxIPEg+C4geC4teC5iSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhXSJ9!"
        )
      )
      });
  
      export { translations as default };
    