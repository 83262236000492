import { CUSTOMER_SERVICE_HOURS } from '@seek/shared-constants';
import { useTranslations } from '@vocab/react';
import { Alert, Stack, Text, TextLink } from 'braid-design-system';

import useAuthenticatedTracking from 'src/modules/AnalyticsProvider/useAuthenticatedViewTracking';
import type { EmployerSiteName } from 'src/types';
import { CsPhoneNumber } from 'src/views/shared/CsPhoneNumber/CsPhoneNumber';

import translations from './.vocab';

export interface Props {
  site: EmployerSiteName;
  isAnz: boolean;
  contactCustomerServiceUrl: string;
  description?: string;
  nextStep?: string;
  showCsContactStep?: boolean;
  trackingEventName?: string;
  trackingAdditionalInfo?: Record<string, string>;
  triggerViewTracking?: boolean;
}

const ErrorMessage = ({
  site,
  isAnz,
  contactCustomerServiceUrl,
  description = 'Something went wrong.',
  nextStep = 'To continue',
  showCsContactStep = true,
  trackingEventName,
  trackingAdditionalInfo,
  triggerViewTracking,
}: Props) => {
  useAuthenticatedTracking({
    eventName: trackingEventName,
    additionalInfo: {
      ...trackingAdditionalInfo,
      alertShown: `"title":"${description}"`,
    },
    triggerViewTracking,
  });

  const { t } = useTranslations(translations);

  return (
    <div data-test="error-message-alert">
      <Alert tone="critical">
        <Stack space="small">
          <Text>{description}</Text>
          {showCsContactStep && (
            <>
              {isAnz ? (
                <Text>
                  <span data-testid="next-step">{nextStep}</span>
                  {', '}
                  Please call our customer service team on{' '}
                  <CsPhoneNumber site={site} /> {CUSTOMER_SERVICE_HOURS[site]}
                </Text>
              ) : (
                <Text>
                  <span data-testid="next-step">{nextStep}</span>
                  {', '}
                  {t('reach out to our Customer Service team on')}{' '}
                  <TextLink href={contactCustomerServiceUrl}>
                    {t('Contact Customer Service')}
                  </TextLink>
                </Text>
              )}
            </>
          )}
        </Stack>
      </Alert>
    </div>
  );
};

export default ErrorMessage;
