export const IconDesktopWarning = (props: any) => (
  <svg
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M105.201 91.62H21.936a4.996 4.996 0 0 1-4.996-4.997v-8.326h93.257v8.326a4.996 4.996 0 0 1-4.996 4.996ZM80.221 111.603H46.915a3.331 3.331 0 0 1 0-6.662h33.306a3.331 3.331 0 0 1 0 6.662Z"
      fill="#6E56E6"
    />
    <path
      d="M76.89 91.62H50.247v13.321h26.645V91.619ZM21.922 25.008h83.264a4.999 4.999 0 0 1 4.996 4.996v48.293H16.926V30.004a4.998 4.998 0 0 1 4.996-4.996Z"
      fill="#392BA7"
    />
    <path
      d="M63.568 86.62a1.665 1.665 0 1 0 0-3.33 1.665 1.665 0 0 0 0 3.33Z"
      fill="#fff"
    />
    <path
      d="M60.353 39.208c1.574-2.707 5.485-2.707 7.06 0l13 22.352c1.583 2.722-.381 6.136-3.53 6.136H50.882c-3.15 0-5.113-3.414-3.53-6.136l13-22.352Z"
      fill="#FDC221"
    />
    <path
      d="M63.932 61.144a1.432 1.432 0 1 0 0-2.864 1.432 1.432 0 0 0 0 2.864ZM63.932 55.4a1.432 1.432 0 0 0 1.432-1.431V48.24a1.432 1.432 0 0 0-2.864 0v5.728a1.432 1.432 0 0 0 1.432 1.432Z"
      fill="#2E3849"
    />
  </svg>
);
