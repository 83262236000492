import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';

const accountParts = gql`
  fragment accountParts on HirerAccountOption {
    name
    hirerAccountId
    billingId
    accessRightStatus
    hirerAccountStatus
    redirectUrl(input: $input)
    role
  }
`;

export const GET_ACCOUNT_SELECTION_QUERY = gql`
  ${accountParts}
  query GetAccountSelection($input: RedirectUrlInput) {
    accountSelectionV3 {
      ... on AccountSelectionResultsV3 {
        __typename
        totalCount
        defaultAccount {
          ...accountParts
        }
        edges {
          node {
            ...accountParts
            childAccounts {
              ...accountParts
              # Note: we are only getting child accounts one level deep. These child accounts won't have a childAccounts field.
            }
          }
        }
      }
      ... on ResponseError {
        __typename
        error
      }
    }
  }
`;

// this one is needed by registration page.
const getAccountSelectionForRegistrationPage = async () => {
  const graphqlClient = getClient();
  const result = await graphqlClient.query({
    query: GET_ACCOUNT_SELECTION_QUERY,
  });

  return result.data.accountSelectionV3;
};

export default getAccountSelectionForRegistrationPage;
