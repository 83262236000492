// Object key is the id of the entry in Hygraph
export const shopfrontAnalyticsEvents = {
  // contact us link
  cldjfwzyg03eq0c3zcocax0ii: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: `reach out to us`,
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - hero',
    entryPoint: 'shopfront - hero',
  },
  // help centre link
  cldjfzg0g03ay0a44kq15r9cf: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: 'help centre',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - hero',
    entryPoint: 'shopfront - hero',
  },
  // whatsapp link - this is explicity set inside hygraph UI in the rich text link attribute ID
  whatsapp_link: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: 'WhatsApp',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - hero',
    entryPoint: 'shopfront - hero',
  },
  // get hiring advice
  clh7aporl2jzk0b2i97c99yj0: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: 'get hiring advice',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - hiring advice',
    entryPoint: 'shopfront - hiring advice',
  },
  // find out about talent search
  clh7ahvzo2jxd0b2qxkkwe39h: {
    eventName: 'content_element_pressed',
    elementDesign: 'button',
    elementText: 'find out about seek talent search',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - candidate recommendations',
    entryPoint: 'shopfront - candidate recommendations',
  },
  // create account button
  clh8e961134jw0b2lzl7fjtlz: {
    eventName: 'content_element_pressed',
    elementDesign: 'button',
    elementText: 'create account',
    elementAction: 'internal redirect',
    actionOrigin:
      'shopfront - find the right people | shopfront - 3 simple steps | shopfront - employers choose us',
    entryPoint:
      'shopfront - find the right people | shopfront - 3 simple steps | shopfront - employers choose us',
  },
  // view home page
  home_page_view: {
    siteSection: 'shopfront',
    eventName: 'shopfront_displayed',
  },
  // register email interaction
  form_first_interaction_pressed: {
    eventName: 'form_first_interaction_pressed',
    actionOrigin: 'shopfront - email registration',
    entryPoint: 'shopfront - email registration',
  },
  // register email validation fail
  form_validation_error_displayed: {
    eventName: 'form_validation_error_displayed',
    validationType: 'field',
  },
  // register email button clicked
  registration_button_pressed: {
    eventName: 'registration_button_pressed',
    actionOrigin: 'shopfront - email registration',
    entryPoint: 'shopfront - email registration',
  },
};
