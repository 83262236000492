interface AccountStatusIndicatorProps {
  isActive: boolean;
}

// Custom styles to match incoming New Visual Language (NVL)
// https://myseek.atlassian.net/wiki/spaces/DPB/pages/2200015222/Background+and+context
const AccountStatusIndicator = ({ isActive }: AccountStatusIndicatorProps) => (
  <div
    style={{
      width: '8px',
      height: '8px',
      borderRadius: '8px',
      backgroundColor: isActive ? '#8BDEC5' : '#FDC221',
    }}
  />
);

export default AccountStatusIndicator;
