import type { TokenData } from '@seek/online-identity';
import { useState, useEffect } from 'react';

import getTokenData from './getTokenData';

export default () => {
  const [tokenData, setTokenData] = useState<TokenData | null>(null);
  useEffect(() => {
    (async () => {
      const data = await getTokenData();
      setTokenData(data);
    })();
  }, []);

  return tokenData;
};
